import React, { useContext, useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import ApiService from "app/services/config";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ModalNovaConta from "../ModalNovaConta/ModalNovaConta";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import BotoesExportarDados from "../BotoesExportarDados";
import PaginationComponent from "../Pagination/Pagination";

const OBJETO_STATUS_PAGAMENTO = {
  pendente: {
    bg: "#FFCB3C",
    cor: "#484646",
    texto: "Pendente",
  },
  pago: {
    bg: "#28A745",
    cor: "#FFFFFF",
    texto: "Pago",
  },
  atrasado: {
    bg: "#DC3545",
    cor: "#FFFFFF",
    texto: "Atrasado",
  },
};

const AccordionListaContasAPagar = ({
  page,
  setPage,
  perPage,
  setPerPage,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  searchTerm,
  setSearchTerm,
  count,
  setCount,
  contasAPagar,
  setContasAPagar,
  selectedContasIds,
  setSelectedContasIds,
  handleDeleteContas,
}) => {
  const [todasAsContasSelecionadas, setTodasAsContasSelecionadas] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const [isModalNovaContaAberto, setIsModalNovaContaAberto] = useState(false);
  const [selectedConta, setSelectedConta] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const { hasPermission } = useContext(PermissionContext);
  const permission = hasPermission("Financeiro", "create");

  if (!hasPermission("Financeiro", "read")) {
    navigate("/app");
  }

  const navigate = useNavigate();

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleSelecionarTodasAsContas = () => {
    const todosIdsContas = contasAPagar.map((conta) => conta.id);
    if (!todasAsContasSelecionadas) {
      setSelectedContasIds(todosIdsContas);
    } else {
      setSelectedContasIds([]);
    }
    setTodasAsContasSelecionadas(!todasAsContasSelecionadas);
  };

  const handleOpenModalNovaConta = (value) => {
    const conta = {
      id: value?.rowData?.[1],
      descricao: value?.rowData?.[2],
      valor: value?.rowData?.[3],
      status_pagamento: value?.rowData?.[4],
      data_vencimento: value?.rowData?.[5],
      categoria: value?.rowData?.[7]?.[0]?.categoria?.id,
      beneficiario: value?.rowData?.[6],
    };

    setSelectedConta(conta);
    setIsModalNovaContaAberto(!isModalNovaContaAberto);
  };

  const handleCheckboxChange = (idConta) => {
    setContasAPagar((contasPrevias) =>
      contasPrevias.map((conta) =>
        conta.id === idConta
          ? { ...conta, isSelected: !conta.isSelected }
          : conta
      )
    );

    setSelectedContasIds((idsSelecionadosPrevios) => {
      const estaSelecionada = idsSelecionadosPrevios.includes(idConta);
      const novosIdsSelecionados = estaSelecionada
        ? idsSelecionadosPrevios.filter((id) => id !== idConta)
        : [...idsSelecionadosPrevios, idConta];

      setMostrarBotaoDeletarMuitos(novosIdsSelecionados.length > 0);

      return novosIdsSelecionados;
    });

    setTodasAsContasSelecionadas(false);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon />}
        style={{
          borderRadius: "0",
          border: "none",
          backgroundColor: "#F0F0F0",
          boxShadow: "none",
        }}
        sx={{
          px: 5,
        }}
      >
        <Typography fontWeight={600} style={{ color: "#212529" }}>
          Lista de Contas a Pagar
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          borderRadius: "0",
          border: "none",
          height: "auto",
        }}
      >
        <Grid container width="100%" p={2}>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <TextField
                variant="standard"
                placeholder="Pesquisar..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(inputValue);
                  }
                }}
                InputProps={{
                  sx: {
                    borderRadius: 2,
                    border: "1px solid #4E5526",
                    paddingLeft: 2,
                    height: "36px",
                    "&:hover": { borderColor: "#3B4220" },
                    "&.Mui-focused": { borderColor: "#4E5526" },
                    color: "#4E5526",
                    "&::placeholder": { color: "red", opacity: 1 },
                    width: 350,
                  },
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        sx={{
                          backgroundColor: "#4E5526",
                          borderRadius: 2,
                          minWidth: "36px",
                          height: "36px",
                          padding: 0,
                          "&:hover": { backgroundColor: "#3B4220" },
                        }}
                        onClick={() => handleSearch(inputValue)}
                      >
                        <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
            <Stack direction="row" gap={2}>
              {selectedContasIds.length > 0 ? (
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    handleDeleteContas("multiplas", null);
                  }}
                  sx={{
                    borderRadius: 2,
                    paddingX: 2,
                    backgroundColor: "#C84E4D",
                    textTransform: "none",
                  }}
                >
                  Excluir {selectedContasIds.length} conta (s)
                </Button>
              ) : null}
              <BotoesExportarDados
                dados_planilha={contasAPagar}
                nome_arquivo_planilha={"contas_a_pagar"}
                colunas_planilha={[
                  "id",
                  "descricao",
                  "valor",
                  "status_pagamento",
                  "beneficiario",
                ]}
                titulo="Contas a pagar"
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          width="100%"
          alignItems={"center"}
          my={2}
          p={1}
          spacing={2}
        >
          <Grid item xs={0.8}>
            <Checkbox
              checked={todasAsContasSelecionadas}
              onChange={handleSelecionarTodasAsContas}
              disabled={!permission}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              Descrição
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              Valor
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              Status de Pagamento
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              Data
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              Beneficiário
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              Ações
            </Typography>
          </Grid>
        </Grid>
        {contasAPagar && contasAPagar.length > 0 ? (
          contasAPagar?.map((conta, index) => (
            <Grid
              container
              width="100%"
              alignItems={"center"}
              my={-2}
              p={1}
              spacing={2}
              key={`${conta.id}-${index}`}
              style={{
                borderBottom: "1px solid #4E5526",
                paddingBottom: 14,
              }}
            >
              <Grid item xs={0.8}>
                <Checkbox
                  checked={selectedContasIds.includes(conta?.id)}
                  onChange={() => {
                    handleCheckboxChange(conta.id);
                  }}
                  disabled={!permission}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  fontWeight={400}
                  fontSize={16}
                  style={{ color: "#212529" }}
                >
                  {conta?.descricao ?? "N/I"}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography
                  fontWeight={400}
                  fontSize={16}
                  style={{ color: "#212529" }}
                >
                  {conta?.valor
                    ? Number(conta.valor).toLocaleString("pt-BR", {
                        currency: "BRL",
                        style: "currency",
                      })
                    : "N/I"}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography
                  fontWeight={400}
                  fontSize={16}
                  style={{ color: "#212529" }}
                >
                  <Box
                    sx={{
                      width: "140px",
                      height: "auto",
                      color:
                        OBJETO_STATUS_PAGAMENTO[conta?.status_pagamento]?.cor,
                      background:
                        OBJETO_STATUS_PAGAMENTO[conta?.status_pagamento]?.bg,
                      textAlign: "center",
                      padding: 1,
                      borderRadius: "10px",
                    }}
                  >
                    {OBJETO_STATUS_PAGAMENTO[conta?.status_pagamento]?.texto}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Typography
                  fontWeight={400}
                  fontSize={16}
                  style={{ color: "#212529" }}
                >
                  {conta?.data_vencimento
                    ? moment.utc(conta?.data_vencimento).format("DD/MM/YYYY")
                    : "N/I"}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  fontWeight={400}
                  fontSize={16}
                  style={{ color: "#212529" }}
                >
                  {conta?.beneficiario ? conta?.beneficiario :  "N/I"}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography
                  fontWeight={400}
                  fontSize={16}
                  style={{ color: "#212529" }}
                >
                  <DeleteIcon
                    style={{
                      color: "#C84E4D",
                      cursor: "pointer",
                      fontSize: 24,
                    }}
                    onClick={() => {
                      handleDeleteContas("unica", conta.id);
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          ))
        ) : (
          <Box
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            p={2}
          >
            Nenhuma conta registrada
          </Box>
        )}
        <PaginationComponent
          page={page}
          setPage={setPage}
          count={count}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionListaContasAPagar;
