import { Card } from "@mui/material";
import React from "react";

const AbaConsolidacaoFC = ({
  children,
  nomeAba,
  isAbaSelecionada,
  setAbaSelecionada,
}) => {
  return (
    <Card
      width="100%"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "60px",
        color: isAbaSelecionada ? 'white' : 'black',
        background: isAbaSelecionada ? '#95A64A' : '#F4F4F4',
        fontWeight: '600',
        cursor: 'pointer',
        boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
      }}
      onClick={() => setAbaSelecionada(nomeAba)}
    >
        {children}
    </Card>
  );
};

export default AbaConsolidacaoFC;
