import React from "react";
import MetricCard from "../MetricCard/MetricCard";
import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import BotoesExportarDados from "../BotoesExportarDados";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import QueueIcon from "@mui/icons-material/Queue";
import VisibilityIcon from "@mui/icons-material/Visibility";
const RegimeCompetenciaArea = ({
  data,
  indicators,
  setSortByFluxo,
  setSortOrderFluxo,
  handleSearchFluxo,
  inputValue,
  setInputValue,
}) => {
  return (
    <>
      <Grid container width="100%" my={2} spacing={2}>
        <Grid item xs={3}>
          <MetricCard
            title={"Resultado líquido s/ vendas"}
            value={indicators.resultadoLiquidoSemVendas}
          />
        </Grid>
        <Grid item xs={3}>
          <MetricCard
            title={"Margem líquida"}
            value={indicators.margemLiquida}
          />
        </Grid>
        <Grid item xs={3}>
          <MetricCard
            title={"Margem de contribuição"}
            value={indicators.margemContribuicao}
          />
        </Grid>
        <Grid item xs={3}>
          <MetricCard
            title={"MC%"}
            value={indicators.margemContribuicaoPorcentagem}
          />
        </Grid>
        <Grid item xs={3}>
          <MetricCard
            title={"Representatividade custo fixo"}
            value={indicators.representatividadeCustoFixo}
          />
        </Grid>
        <Grid item xs={3}>
          <MetricCard
            title={"Ponto de equilíbrio"}
            value={indicators.pontoEquilibrio}
          />
        </Grid>
        <Grid item xs={3}>
          <MetricCard
            title={"Ponto de equilíbrio econômico 15%"}
            value={indicators.pontoEquilibrio15}
          />
        </Grid>
        <Grid item xs={3}>
          <MetricCard
            title={"Ponto de equilíbrio econômico 20%"}
            value={indicators.pontoEquilibrio20}
          />
        </Grid>
      </Grid>
      <Card sx={{ width: "100%", minHeight: "100px", my: 3, p: 2 }}>
        <Grid container width="100%" spacing={2}>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <TextField
                variant="standard"
                placeholder="Pesquisar..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearchFluxo(inputValue);
                  }
                }}
                InputProps={{
                  sx: {
                    borderRadius: 2,
                    border: "1px solid #4E5526",
                    paddingLeft: 2,
                    height: "36px",
                    "&:hover": { borderColor: "#3B4220" },
                    "&.Mui-focused": { borderColor: "#4E5526" },
                    color: "#4E5526",
                    "&::placeholder": { color: "red", opacity: 1 },
                    width: 350,
                  },
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        sx={{
                          backgroundColor: "#4E5526",
                          borderRadius: 2,
                          minWidth: "36px",
                          height: "36px",
                          padding: 0,
                          "&:hover": { backgroundColor: "#3B4220" },
                        }}
                        onClick={() => handleSearchFluxo(inputValue)}
                      >
                        <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <BotoesExportarDados
              dados_planilha={data}
              titulo={"Regime de Competência - Financeiro"}
              nome_arquivo_planilha={"regime_competencia_"}
              colunas_planilha={[
                "id",
                "descricao",
                "categoria_mae_desc",
                "entrada",
                "saida",
                "tipo",
                "porcentagem_total_tipo",
                "porcentagem_op",
              ]}
            />
          </Grid>
        </Grid>
        <Grid
          container
          width="100%"
          alignItems={"center"}
          my={2}
          p={1}
          spacing={2}
        >
          <Grid item xs={4}>
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              Código
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              Descrição
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              Valor
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              100%
            </Typography>
          </Grid>
        </Grid>
        {data && data.length > 0 ? (
          data.map((fluxo, index) => {
            let bgColor = "#fff";

            const length = fluxo?.codigo?.length;

            switch (length) {
              case 1:
                bgColor = "#d1d1d1";
                break;
              case 2:
                bgColor = "#e8e8e8";
                break;
              default:
                bgColor = "#fff";
                break;
            }

            if (fluxo?.codigo === "30") {
              bgColor = "#d9d9d9";
            }

            return (
              <Grid
                container
                width="100%"
                alignItems={"center"}
                justifyContent={"center"}
                my={-2}
                p={2}
                spacing={2}
                key={`${fluxo.id}-${index}`}
                style={{
                  background: bgColor,
                  height: "80px",
                }}
              >
                <Grid item xs={4}>
                  <Typography fontWeight={600} style={{ color: "#212529" }}>
                    {fluxo?.codigo}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography style={{ color: "#212529" }}>
                    {fluxo?.descricao}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{ color: "#212529" }}>
                    {String(fluxo?.tipo).toLowerCase() === "saída"
                      ? Number(fluxo?.saida).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })
                      : Number(fluxo?.entrada).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography style={{ color: "#212529" }}>
                    {fluxo?.porcentagem_total_tipo
                      ? parseFloat(fluxo?.porcentagem_total_tipo).toFixed(2)
                      : 0.0}
                    %
                  </Typography>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Box
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            p={2}
          >
            Nenhum dado registrado
          </Box>
        )}
      </Card>
    </>
  );
};

export default RegimeCompetenciaArea;
