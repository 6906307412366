import { Grid, Stack, Typography } from "@mui/material";
import {
  mascaraCelular,
  mascaraCNPJ,
  mascaraCPF,
  mascaraFixo,
} from "app/utils/mascaraDados";
import React from "react";

const DetalhesClienteContrato = ({ contrato, mostrarUnidades = true, paddingX = 0 }) => {
  return (
    <>
      <Grid container width={"100%"} px={paddingX} spacing={1} sx={{background: '#FFFFFF'}}>
        {/* Título */}
        <Grid item xs={12}>
          <Typography
            fontWeight={600}
            fontSize={28}
            color={"#212529"}
            marginBottom={1}
          >
            Dados do Cliente
          </Typography>
        </Grid>

        {/* Container para os itens da esquerda */}
        <Grid container item xs={6} spacing={1}>
          {/* Cliente */}
          <Grid item xs={12}>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={600} fontSize={16}>
                Cliente:
              </Typography>
              <Typography fontSize={16}>{contrato?.Cliente?.nome}</Typography>
            </Stack>
          </Grid>

          {/* E-mail */}
          <Grid item xs={12}>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={600} fontSize={16}>
                E-mail:
              </Typography>
              <Typography fontSize={16}>{contrato?.Cliente?.email}</Typography>
            </Stack>
          </Grid>

          {/* Telefone */}
          <Grid item xs={12}>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={600} fontSize={16}>
                Telefone:
              </Typography>
              <Typography fontSize={16}>
                {contrato?.Cliente?.telefone
                  ? contrato.Cliente.tipo_telefone === "fixo" ||
                    contrato.Cliente.tipo_telefone === "telefone"
                    ? mascaraFixo(contrato.Cliente.telefone)
                    : contrato.Cliente.tipo_telefone === "celular"
                    ? mascaraCelular(contrato.Cliente.telefone)
                    : ""
                  : "N/I"}
              </Typography>
            </Stack>
          </Grid>

          {/* CPF ou CNPJ */}
          <Grid item xs={12}>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={600} fontSize={16}>
                {contrato?.Cliente?.tipo_pessoa === "fisica" ? "CPF" : "CNPJ"}:
              </Typography>
              <Typography fontSize={16}>
                {contrato?.Cliente?.tipo_pessoa === "fisica"
                  ? mascaraCPF(contrato?.Cliente?.cpf)
                  : mascaraCNPJ(contrato?.Cliente?.cnpj)}
              </Typography>
            </Stack>
          </Grid>

          {/* Segmento do cliente */}
          <Grid item xs={12}>
            <Stack direction="row" gap={1}>
              <Typography fontWeight={600} fontSize={16}>
                Segmento do cliente:
              </Typography>
              <Typography fontSize={16}>
                {contrato?.Cliente?.segmento_cliente?.descricao ?? "N/I"}
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        {/* Container para os itens da direita */}
        {mostrarUnidades ? (
          <Grid container item xs={6} spacing={1}>
            {/* Unidades */}
            <Grid item xs={12}>
              <Typography fontWeight={600} fontSize={16} marginBottom={1}>
                Unidades
              </Typography>
              <Stack spacing={1}>
                {contrato?.unidades?.length > 0 ? (
                  contrato.unidades.map((unidade, index) => (
                    <Typography key={index} fontSize={16}>
                      • {unidade?.nomeUnidade || "Sem descrição"}
                    </Typography>
                  ))
                ) : (
                  <Typography fontSize={16}>N/A</Typography>
                )}
              </Stack>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default DetalhesClienteContrato;
