import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  Toolbar,
  useMediaQuery,
  Chip,
  TableCell,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Popper,
  IconButton,
  Paper,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import ApiService from "app/services/config";
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { LoadingButton } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { PermissionContext } from "app/contexts/PermissionContext";
import MetricCard from "app/components/MetricCard/MetricCard";
import * as dayjs from "dayjs";
import pt from "dayjs/locale/pt-br";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ptBR } from "@mui/x-date-pickers/locales";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AbaDashboard from "app/components/AbaDashboard";
import { YearCalendar } from "@mui/x-date-pickers";
import { ChevronLeft, Delete } from "@mui/icons-material";
import { ChevronRight } from "@mui/icons-material";
import { CalendarMonth } from "@mui/icons-material";
import AbaConsolidacaoFC from "app/components/AbaConsolidacaoFC";
import SearchIcon from "@mui/icons-material/Search";
import BotoesExportarDados from "app/components/BotoesExportarDados";
import { MESES_ANOS } from "app/utils/mapeamentosNomes";

const ConsolidacaoFcList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const [consolidacao, setConsolidacao] = useState([]);
  const [selectedTiposServicoIds, setSelectedTiposServicoIds] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [indicators, setIndicators] = useState([]);
  const [demonstrativoResultados, setDemonstrativoResultados] = useState([]);
  const [
    indicatorsDemonstrativoResultados,
    setIndicatorsDemonstrativoResultados,
  ] = useState([]);
  const [demonstrativoSetorProdutivo, setDemonstrativoSetorProdutivo] =
    useState([]);
  const [indicatorsSetorProdutivo, setIndicatorsSetorProdutivo] = useState([]);
  let [date, setDate] = useState(dayjs().startOf("month"));
  const [dataQuery, setDataQuery] = useState(dayjs().get("year"));
  const [isLoading, setIsLoading] = useState(false);
  // consolidacao-demonstrativo-setores-produtivos
  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  if (!hasPermission("Financeiro", "read")) {
    navigate("/app");
  }

  const [abaSelecionada, setAbaSelecionada] = useState("indicadores");
  const [opcaoAbaSelecionada, setOpcaoAbaSelecionada] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const calendarRef = useRef(null);

  const handlePrevYear = () => {
    const newDate = date.subtract(1, "year");
    setDate(newDate);
    setDataQuery(newDate.format("YYYY"));
  };

  const handleNextYear = () => {
    const newDate = date.add(1, "year");
    setDate(newDate);
    setDataQuery(newDate.format("YYYY"));
  };

  const handleOpenDatePicker = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleCloseDatePicker = () => {
    setOpen(false);
  };

  const dadosExportacaoPlanilha = {
    fluxo_caixa: {
      data: indicators,
      titulo: "Indicadores de Fluxo de Caixa",
      nome_planilha: "indicadores_fluxo_caixa_",
      colunas_planilha: [
        "mes",
        "saldoInicial",
        "resultadoTotalCaixa",
        "resultadoTotalCaixaAvg",
        "saldoFinal",
        "saldoFinalAvg",
        "resultadoOperacionalCaixa",
        "resultadoOperacionalCaixaAvg",
        "resultadoFluxoInvestimento",
        "resultadoFluxoInvestimentoAvg",
      ],
    },
    demonstrativo_resultados: {
      data: indicatorsDemonstrativoResultados,
      titulo: "Indicadores Demonstrativo de Resultados",
      nome_planilha: "indicadores_demonstrativo_resultados_",
      colunas_planilha: [
        "mes",
        "resultadoLiquidoSemVendas",
        "margemLiquida",
        "margemContribuicao",
        "margemContribuicaoPorcentagem",
        "pontoEquilibro",
        "pontoEquilibrio15",
        "pontoEquilibrio20",
      ],
    },
    consolidacao_fluxo_caixa: {
      data: consolidacao,
      titulo: "Consolidação - Fluxo de Caixa",
      nome_planilha: "consolidacao_fluxo_caixa_",
      colunas_planilha: [
        "codigo",
        "descricao",
        "tipo",
        "entrada_janeiro",
        "entrada_fevereiro",
        "entrada_marco",
        "entrada_abril",
        "entrada_maio",
        "entrada_junho",
        "entrada_julho",
        "entrada_agosto",
        "entrada_setembro",
        "entrada_outubro",
        "entrada_novembro",
        "entrada_dezembro",
        "saida_janeiro",
        "saida_fevereiro",
        "saida_marco",
        "saida_abril",
        "saida_maio",
        "saida_junho",
        "saida_julho",
        "saida_agosto",
        "saida_setembro",
        "saida_outubro",
        "saida_novembro",
        "saida_dezembro",
        "porcentagem_total_tipo_janeiro",
        "porcentagem_total_tipo_fevereiro",
        "porcentagem_total_tipo_marco",
        "porcentagem_total_tipo_abril",
        "porcentagem_total_tipo_maio",
        "porcentagem_total_tipo_junho",
        "porcentagem_total_tipo_julho",
        "porcentagem_total_tipo_agosto",
        "porcentagem_total_tipo_setembro",
        "porcentagem_total_tipo_outubro",
        "porcentagem_total_tipo_novembro",
        "porcentagem_total_tipo_dezembro",
      ],
    },
    consolidacao_resultados: {
      data: demonstrativoResultados,
      titulo: "Consolidação Demonstrativo de Resultados",
      nome_planilha: "consolidacao_demonstrativo_resultados_",
      colunas_planilha: [
        "codigo",
        "descricao",
        "tipo",
        "entrada_janeiro",
        "entrada_fevereiro",
        "entrada_marco",
        "entrada_abril",
        "entrada_maio",
        "entrada_junho",
        "entrada_julho",
        "entrada_agosto",
        "entrada_setembro",
        "entrada_outubro",
        "entrada_novembro",
        "entrada_dezembro",
        "saida_janeiro",
        "saida_fevereiro",
        "saida_marco",
        "saida_abril",
        "saida_maio",
        "saida_junho",
        "saida_julho",
        "saida_agosto",
        "saida_setembro",
        "saida_outubro",
        "saida_novembro",
        "saida_dezembro",
        "porcentagem_total_tipo_janeiro",
        "porcentagem_total_tipo_fevereiro",
        "porcentagem_total_tipo_marco",
        "porcentagem_total_tipo_abril",
        "porcentagem_total_tipo_maio",
        "porcentagem_total_tipo_junho",
        "porcentagem_total_tipo_julho",
        "porcentagem_total_tipo_agosto",
        "porcentagem_total_tipo_setembro",
        "porcentagem_total_tipo_outubro",
        "porcentagem_total_tipo_novembro",
        "porcentagem_total_tipo_dezembro",
      ],
    },
    consolidacao_setores_produtivos: {
      data: demonstrativoSetorProdutivo,
      titulo: "Consolidação Demonstrativo de Resultado de Setores Produtivos",
      nome_planilha: "consolidacao_demonstrativo_resultado_produtivos",
      colunas_planilha: [
        "codigo",
        "descricao",
        "tipo",
        "entrada",
        "entrada_3",
        "entrada_4",
        "entrada_5",
        "entrada_6",
        "saida",
        "saida_3",
        "saida_4",
        "saida_5",
        "saida_6",
      ],
    },
  };

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const getConsolidacao = useCallback(async () => {
    setIsLoading(true);
    try {
      let apiUrl = `controlecaixa/consolidacao-fc`;

      if (date) {
        apiUrl += `?year=${date}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          setConsolidacao(response.data.consolidacaoFc);
          setIndicators(response.data.indicators);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, [date, searchTerm]);

  const getDemonstrativoResultados = useCallback(async () => {
    setIsLoading(true);
    try {
      let apiUrl = `controlecaixa/consolidacao-demonstrativo-anual`;

      if (date) {
        apiUrl += `?year=${date}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          setDemonstrativoResultados(
            response.data.demonstrativoResultadosAnual
          );
          setIndicatorsDemonstrativoResultados(
            response.data.indicatorsDemonstrativoResultadosAnual
          );
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, [date, searchTerm]);

  const getDemonstrativoSetorProdutivo = useCallback(async () => {
    setIsLoading(true);
    try {
      let apiUrl = `controlecaixa/consolidacao-demonstrativo-setores-produtivos`;

      if (date) {
        apiUrl += `?year=${date}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          setDemonstrativoSetorProdutivo(
            response.data.demonstrativoSetorProdutivo
          );
          setIndicatorsSetorProdutivo(response.data.indicatorsProdutivo);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, [date, searchTerm]);

  useEffect(() => {
    getConsolidacao();
    getDemonstrativoResultados();
    getDemonstrativoSetorProdutivo();
  }, [date, searchTerm]);

  return (
    <JumboContentLayout
      header={<PageHeader title={"Consolidação do Fluxo de Caixa"} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}

      <Grid container width="100%" spacing={2}>
        <Grid item xs={2}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
          >
            <Stack
              ref={calendarRef}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                border: "1px solid #78794a",
                borderRadius: "16px",
                padding: "4px 10px",
                backgroundColor: "white",
                width: "max-content",
                height: "35px",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <IconButton onClick={handlePrevYear} sx={{ color: "#78794a" }}>
                <ChevronLeft />
              </IconButton>

              <Typography sx={{ fontSize: "16px", color: "#212529" }}>
                {date.format("YYYY")}
              </Typography>

              <IconButton
                sx={{ color: "black" }}
                onClick={handleOpenDatePicker}
              >
                <CalendarMonth />
              </IconButton>

              <IconButton onClick={handleNextYear} sx={{ color: "#78794a" }}>
                <ChevronRight />
              </IconButton>
            </Stack>

            <Popper
              open={open}
              anchorEl={anchorEl}
              placement="bottom-start"
              disablePortal={true}
              slotProps={{
                popper: { sx: { background: "white" } },
                desktopPaper: { sx: { background: "white" } },
              }}
              modifiers={[
                {
                  name: "preventOverflow",
                  options: {
                    boundary: "window",
                  },
                },
                {
                  name: "offset",
                  options: {
                    offset: [0, 10],
                  },
                },
              ]}
              sx={{
                zIndex: 9999,
              }}
            >
              <YearCalendar
                value={date}
                onChange={(newDate) => {
                  setDate(dayjs(newDate));
                  setDataQuery(dayjs(newDate).format("YYYY"));
                  setOpen(false);
                }}
              />
            </Popper>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <AbaDashboard
            isAbaSelecionada={abaSelecionada === "indicadores"}
            nomeAba={"indicadores"}
            setAbaSelecionada={setAbaSelecionada}
            setFiltrosPesquisa={() => {}}
            onClick={() => {
              setOpcaoAbaSelecionada(null);
            }}
          >
            INDICADORES
          </AbaDashboard>
        </Grid>
        <Grid item xs={2}>
          <AbaDashboard
            isAbaSelecionada={abaSelecionada === "consolidacao"}
            nomeAba={"consolidacao"}
            setAbaSelecionada={setAbaSelecionada}
            setFiltrosPesquisa={() => {}}
            onClick={() => {
              setOpcaoAbaSelecionada(null);
            }}
          >
            CONSOLIDAÇÃO
          </AbaDashboard>
        </Grid>
      </Grid>

      <Paper width="100%" p={20} sx={{ my: 6 }}>
        <Grid container width="100%" spacing={2} p={2}>
          {abaSelecionada === "indicadores" ? (
            <>
              <Grid item xs={4}>
                <AbaConsolidacaoFC
                  isAbaSelecionada={opcaoAbaSelecionada === "fluxo_caixa"}
                  nomeAba={"fluxo_caixa"}
                  setAbaSelecionada={setOpcaoAbaSelecionada}
                >
                  Fluxo de Caixa
                </AbaConsolidacaoFC>
              </Grid>
              <Grid item xs={4}>
                <AbaConsolidacaoFC
                  isAbaSelecionada={
                    opcaoAbaSelecionada === "demonstrativo_resultados"
                  }
                  nomeAba={"demonstrativo_resultados"}
                  setAbaSelecionada={setOpcaoAbaSelecionada}
                >
                  Demonstrativo de Resultados
                </AbaConsolidacaoFC>
              </Grid>
              <Grid item xs={4}>
                <AbaConsolidacaoFC
                  isAbaSelecionada={
                    opcaoAbaSelecionada === "demonstrativo_setores_produtivos"
                  }
                  nomeAba={"demonstrativo_setores_produtivos"}
                  setAbaSelecionada={setOpcaoAbaSelecionada}
                >
                  Demonstrativo de Resultados de Setores Produtivos
                </AbaConsolidacaoFC>
              </Grid>
            </>
          ) : null}
          {abaSelecionada === "consolidacao" ? (
            <>
              <Grid item xs={4}>
                <AbaConsolidacaoFC
                  isAbaSelecionada={
                    opcaoAbaSelecionada === "consolidacao_fluxo_caixa"
                  }
                  nomeAba={"consolidacao_fluxo_caixa"}
                  setAbaSelecionada={setOpcaoAbaSelecionada}
                >
                  Fluxo de Caixa
                </AbaConsolidacaoFC>
              </Grid>
              <Grid item xs={4}>
                <AbaConsolidacaoFC
                  isAbaSelecionada={
                    opcaoAbaSelecionada === "consolidacao_resultados"
                  }
                  nomeAba={"consolidacao_resultados"}
                  setAbaSelecionada={setOpcaoAbaSelecionada}
                >
                  Demonstrativo de Resultados
                </AbaConsolidacaoFC>
              </Grid>
              <Grid item xs={4}>
                <AbaConsolidacaoFC
                  isAbaSelecionada={
                    opcaoAbaSelecionada === "consolidacao_setores_produtivos"
                  }
                  nomeAba={"consolidacao_setores_produtivos"}
                  setAbaSelecionada={setOpcaoAbaSelecionada}
                >
                  Demonstrativo de Resultados de Setores Produtivos
                </AbaConsolidacaoFC>
              </Grid>
            </>
          ) : null}
        </Grid>

        {opcaoAbaSelecionada ? (
          <Grid container width="100%" p={2}>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <TextField
                  variant="standard"
                  placeholder="Pesquisar..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch(inputValue);
                    }
                  }}
                  InputProps={{
                    sx: {
                      borderRadius: 2,
                      border: "1px solid #4E5526",
                      paddingLeft: 2,
                      height: "36px",
                      "&:hover": { borderColor: "#3B4220" },
                      "&.Mui-focused": { borderColor: "#4E5526" },
                      color: "#4E5526",
                      "&::placeholder": { color: "red", opacity: 1 },
                      width: 350,
                    },
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          sx={{
                            backgroundColor: "#4E5526",
                            borderRadius: 2,
                            minWidth: "36px",
                            height: "36px",
                            padding: 0,
                            "&:hover": { backgroundColor: "#3B4220" },
                          }}
                          onClick={() => handleSearch(inputValue)}
                        >
                          <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent={"flex-end"}>
              {opcaoAbaSelecionada !== "demonstrativo_setores_produtivos" ? (
                <BotoesExportarDados
                  dados_planilha={
                    dadosExportacaoPlanilha[opcaoAbaSelecionada]?.data
                  }
                  colunas_planilha={
                    dadosExportacaoPlanilha[opcaoAbaSelecionada]
                      ?.colunas_planilha
                  }
                  nome_arquivo_planilha={
                    dadosExportacaoPlanilha[opcaoAbaSelecionada]?.nome_planilha
                  }
                  titulo={dadosExportacaoPlanilha[opcaoAbaSelecionada]?.titulo}
                />
              ) : null}
            </Grid>
          </Grid>
        ) : null}
        {abaSelecionada && opcaoAbaSelecionada === "fluxo_caixa" ? (
          <>
            <Grid container width="100%" my={1} p={2}>
              <Grid item xs={1.2}>
                <Typography fontWeight={600}>Mês</Typography>
              </Grid>
              <Grid item xs={1.2}>
                <Typography fontWeight={600}>Saldo inicial</Typography>
              </Grid>
              <Grid item xs={1.2}>
                <Typography fontWeight={600}>
                  Resultado total de caixa
                </Typography>
              </Grid>
              <Grid item xs={1.2}>
                <Typography fontWeight={600}>
                  Resultado total de caixa %
                </Typography>
              </Grid>
              <Grid item xs={1.2}>
                <Typography fontWeight={600}>Saldo final</Typography>
              </Grid>
              <Grid item xs={1.2}>
                <Typography fontWeight={600}>Saldo final %</Typography>
              </Grid>
              <Grid item xs={1.2}>
                <Typography fontWeight={600}>
                  Resultado operacional de caixa
                </Typography>
              </Grid>
              <Grid item xs={1.2}>
                <Typography fontWeight={600}>
                  Resultado operacional de caixa %
                </Typography>
              </Grid>
              <Grid item xs={1.2}>
                <Typography fontWeight={600}>
                  Resultado fluxo investimento
                </Typography>
              </Grid>
              <Grid item xs={1.2}>
                <Typography fontWeight={600}>
                  Resultado fluxo investimento %
                </Typography>
              </Grid>
            </Grid>
            {indicators && indicators.length > 0 ? (
              indicators.map((indicador, index) => (
                <Grid container width="100%" my={1} p={2} key={index}>
                  <Grid item xs={1.2}>
                    <Typography>
                      {MESES_ANOS[indicador?.mes] || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.2}>
                    <Typography>
                      {Number(indicador?.saldoInicial)
                        ? Number(indicador.saldoInicial).toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "BRL",
                            }
                          )
                        : "R$ 0,00"}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.2}>
                    <Typography>
                      {Number(indicador?.resultadoTotalCaixa)
                        ? Number(indicador.resultadoTotalCaixa).toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "BRL",
                            }
                          )
                        : "R$ 0,00"}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.2}>
                    <Typography>
                      {isNaN(parseFloat(indicador?.resultadoTotalCaixaAvg))
                        ? "0.00"
                        : parseFloat(indicador.resultadoTotalCaixaAvg).toFixed(
                            2
                          )}
                      %
                    </Typography>
                  </Grid>
                  <Grid item xs={1.2}>
                    <Typography>
                      {Number(indicador?.saldoFinal)
                        ? Number(indicador.saldoFinal).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })
                        : "R$ 0,00"}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.2}>
                    <Typography>
                      {isNaN(parseFloat(indicador?.saldoFinalAvg))
                        ? "0.00"
                        : parseFloat(indicador.saldoFinalAvg).toFixed(2)}
                      %
                    </Typography>
                  </Grid>
                  <Grid item xs={1.2}>
                    <Typography>
                      {Number(indicador?.resultadoOperacionalCaixa)
                        ? Number(
                            indicador.resultadoOperacionalCaixa
                          ).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })
                        : "R$ 0,00"}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.2}>
                    <Typography>
                      {isNaN(
                        parseFloat(indicador?.resultadoOperacionalCaixaAvg)
                      )
                        ? "0.00"
                        : parseFloat(
                            indicador.resultadoOperacionalCaixaAvg
                          ).toFixed(2)}
                      %
                    </Typography>
                  </Grid>
                  <Grid item xs={1.2}>
                    <Typography>
                      {Number(indicador?.resultadoFluxoInvestimento)
                        ? Number(
                            indicador.resultadoFluxoInvestimento
                          ).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })
                        : "R$ 0,00"}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.2}>
                    <Typography>
                      {isNaN(
                        parseFloat(indicador?.resultadoFluxoInvestimentoAvg)
                      )
                        ? "0.00"
                        : parseFloat(
                            indicador.resultadoFluxoInvestimentoAvg
                          ).toFixed(2)}
                      %
                    </Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Box
                width={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                p={2}
              >
                Nenhum registro encontrado.
              </Box>
            )}
          </>
        ) : null}
        {abaSelecionada &&
        opcaoAbaSelecionada === "demonstrativo_resultados" ? (
          <>
            <Grid container width="100%" my={1} p={2}>
              <Grid item xs={1.3}>
                <Typography fontWeight={600}>Mês</Typography>
              </Grid>
              <Grid item xs={1.3}>
                <Typography fontWeight={600}>
                  Resultado líquido s/ vendas
                </Typography>
              </Grid>
              <Grid item xs={1.3}>
                <Typography fontWeight={600}>Margem líquida</Typography>
              </Grid>
              <Grid item xs={1.3}>
                <Typography fontWeight={600}>Margem de contribuição</Typography>
              </Grid>
              <Grid item xs={1.3}>
                <Typography fontWeight={600}>MC%</Typography>
              </Grid>
              <Grid item xs={1.3}>
                <Typography fontWeight={600}>
                  Representatividade custo fixo
                </Typography>
              </Grid>
              <Grid item xs={1.3}>
                <Typography fontWeight={600}>Ponto de equilíbrio</Typography>
              </Grid>
              <Grid item xs={1.3}>
                <Typography fontWeight={600}>
                  Ponto de equilíbrio econômico 15$
                </Typography>
              </Grid>
              <Grid item xs={1.3}>
                <Typography fontWeight={600}>
                  Ponto de equilíbrio econômico 20%
                </Typography>
              </Grid>
            </Grid>
            {indicatorsDemonstrativoResultados &&
            indicatorsDemonstrativoResultados.length > 0 ? (
              indicatorsDemonstrativoResultados.map((indicador, index) => (
                <Grid container width="100%" my={1} p={2} key={index}>
                  <Grid item xs={1.3}>
                    <Typography>
                      {MESES_ANOS[indicador?.mes] || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.3}>
                    <Typography>
                      {Number(indicador?.resultadoLiquidoSemVendas)
                        ? Number(
                            indicador.resultadoLiquidoSemVendas
                          ).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })
                        : "R$ 0,00"}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.3}>
                    <Typography>
                      {isNaN(parseFloat(indicador?.margemLiquida))
                        ? "0.00"
                        : parseFloat(indicador.margemLiquida).toFixed(2)}
                      %
                    </Typography>
                  </Grid>
                  <Grid item xs={1.3}>
                    <Typography>
                      {Number(indicador?.margemContribuicao)
                        ? Number(indicador.margemContribuicao).toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "BRL",
                            }
                          )
                        : "R$ 0,00"}
                    </Typography>
                  </Grid>
                  <Grid item xs={1.3}>
                    <Typography>
                      {isNaN(
                        parseFloat(indicador?.margemContribuicaoPorcentagem)
                      )
                        ? "0.00"
                        : parseFloat(
                            indicador.margemContribuicaoPorcentagem
                          ).toFixed(2)}
                      %
                    </Typography>
                  </Grid>
                  <Grid item xs={1.3}>
                    <Typography>
                      {isNaN(parseFloat(indicador?.representatividadeCustoFixo))
                        ? "0.00"
                        : parseFloat(
                            indicador.representatividadeCustoFixo
                          ).toFixed(2)}
                      %
                    </Typography>
                  </Grid>
                  <Grid item xs={1.3}>
                    <Typography>
                      {isNaN(parseFloat(indicador?.pontoEquilibro))
                        ? "0.00"
                        : parseFloat(indicador.pontoEquilibro).toFixed(2)}
                      %
                    </Typography>
                  </Grid>
                  <Grid item xs={1.3}>
                    <Typography>
                      {isNaN(parseFloat(indicador?.pontoEquilibrio15))
                        ? "0.00"
                        : parseFloat(indicador.pontoEquilibrio15).toFixed(2)}
                      %
                    </Typography>
                  </Grid>
                  <Grid item xs={1.3}>
                    {isNaN(parseFloat(indicador?.pontoEquilibrio20))
                      ? "0.00"
                      : parseFloat(indicador.pontoEquilibrio20).toFixed(2)}
                    %
                  </Grid>
                </Grid>
              ))
            ) : (
              <Box
                width={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                p={2}
              >
                Nenhum registro encontrado.
              </Box>
            )}
          </>
        ) : null}
        {abaSelecionada &&
        opcaoAbaSelecionada === "demonstrativo_setores_produtivos" ? (
          <Box width="100%" p={2}>
            <Box
              width="100%"
              textAlign={"center"}
              color={"#212529"}
              fontWeight={600}
              p={2}
              sx={{
                borderBottom: "1px solid #4E5526",
              }}
            >
              Agrossilvopastoril
            </Box>
            <Grid container width="100%" spacing={2} my={1}>
              <Grid item xs={3}>
                <MetricCard
                  title={"Resultado líquido s/ vendas"}
                  value={
                    indicatorsSetorProdutivo?.agro?.resultadoLiquidoSemVendas
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Margem líquida"}
                  value={indicatorsSetorProdutivo?.agro?.margemLiquida}
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Margem de contribuição"}
                  value={indicatorsSetorProdutivo?.agro?.margemContribuicao}
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"MC%"}
                  value={
                    indicatorsSetorProdutivo?.agro
                      ?.margemContribuicaoPorcentagem
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Representatividade custo fixo"}
                  value={
                    indicatorsSetorProdutivo?.agro?.representatividadeCustoFixo
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Ponto de equilíbrio"}
                  value={indicatorsSetorProdutivo?.agro?.pontoEquilibrio}
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Ponto de equilíbrio econômico 15%"}
                  value={indicatorsSetorProdutivo?.agro?.pontoEquilibrio15}
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Ponto de equilíbrio econômico 20%"}
                  value={indicatorsSetorProdutivo?.agro?.pontoEquilibrio20}
                />
              </Grid>
            </Grid>
            <Box
              width="100%"
              textAlign={"center"}
              color={"#212529"}
              fontWeight={600}
              p={2}
              sx={{
                borderBottom: "1px solid #4E5526",
              }}
            >
              Mineração
            </Box>
            <Grid container width="100%" spacing={2} my={1}>
              <Grid item xs={3}>
                <MetricCard
                  title={"Resultado líquido s/ vendas"}
                  value={
                    indicatorsSetorProdutivo?.mineracao
                      ?.resultadoLiquidoSemVendas
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Margem líquida"}
                  value={indicatorsSetorProdutivo?.mineracao?.margemLiquida}
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Margem de contribuição"}
                  value={
                    indicatorsSetorProdutivo?.mineracao?.margemContribuicao
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"MC%"}
                  value={
                    indicatorsSetorProdutivo?.mineracao
                      ?.margemContribuicaoPorcentagem
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Representatividade custo fixo"}
                  value={
                    indicatorsSetorProdutivo?.mineracao
                      ?.representatividadeCustoFixo
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Ponto de equilíbrio"}
                  value={indicatorsSetorProdutivo?.mineracao?.pontoEquilibrio}
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Ponto de equilíbrio econômico 15%"}
                  value={indicatorsSetorProdutivo?.mineracao?.pontoEquilibrio15}
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Ponto de equilíbrio econômico 20%"}
                  value={indicatorsSetorProdutivo?.mineracao?.pontoEquilibrio20}
                />
              </Grid>
            </Grid>
            <Box
              width="100%"
              textAlign={"center"}
              color={"#212529"}
              fontWeight={600}
              p={2}
              sx={{
                borderBottom: "1px solid #4E5526",
              }}
            >
              Prestadores de serviços
            </Box>
            <Grid container width="100%" spacing={2} my={1}>
              <Grid item xs={3}>
                <MetricCard
                  title={"Resultado líquido s/ vendas"}
                  value={
                    indicatorsSetorProdutivo?.prestadores
                      ?.resultadoLiquidoSemVendas
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Margem líquida"}
                  value={indicatorsSetorProdutivo?.prestadores?.margemLiquida}
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Margem de contribuição"}
                  value={
                    indicatorsSetorProdutivo?.prestadores?.margemContribuicao
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"MC%"}
                  value={
                    indicatorsSetorProdutivo?.prestadores
                      ?.margemContribuicaoPorcentagem
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Representatividade custo fixo"}
                  value={
                    indicatorsSetorProdutivo?.prestadores
                      ?.representatividadeCustoFixo
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Ponto de equilíbrio"}
                  value={indicatorsSetorProdutivo?.prestadores?.pontoEquilibrio}
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Ponto de equilíbrio econômico 15%"}
                  value={
                    indicatorsSetorProdutivo?.prestadores?.pontoEquilibrio15
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Ponto de equilíbrio econômico 20%"}
                  value={
                    indicatorsSetorProdutivo?.prestadores?.pontoEquilibrio20
                  }
                />
              </Grid>
            </Grid>
            <Box
              width="100%"
              textAlign={"center"}
              color={"#212529"}
              fontWeight={600}
              p={2}
              sx={{
                borderBottom: "1px solid #4E5526",
              }}
            >
              Conferência
            </Box>
            <Grid container width="100%" spacing={2} my={1}>
              <Grid item xs={3}>
                <MetricCard
                  title={"Resultado líquido s/ vendas"}
                  value={
                    indicatorsSetorProdutivo?.conferencia
                      ?.resultadoLiquidoSemVendas
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Margem líquida"}
                  value={indicatorsSetorProdutivo?.conferencia?.margemLiquida}
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Margem de contribuição"}
                  value={
                    indicatorsSetorProdutivo?.conferencia?.margemContribuicao
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"MC%"}
                  value={
                    indicatorsSetorProdutivo?.conferencia
                      ?.margemContribuicaoPorcentagem
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Representatividade custo fixo"}
                  value={
                    indicatorsSetorProdutivo?.conferencia
                      ?.representatividadeCustoFixo
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Ponto de equilíbrio"}
                  value={indicatorsSetorProdutivo?.conferencia?.pontoEquilibrio}
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Ponto de equilíbrio econômico 15%"}
                  value={
                    indicatorsSetorProdutivo?.conferencia?.pontoEquilibrio15
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <MetricCard
                  title={"Ponto de equilíbrio econômico 20%"}
                  value={
                    indicatorsSetorProdutivo?.conferencia?.pontoEquilibrio20
                  }
                />
              </Grid>
            </Grid>
          </Box>
        ) : null}
        {abaSelecionada &&
        opcaoAbaSelecionada === "consolidacao_fluxo_caixa" ? (
          <Box sx={{ width: "100%", overflowX: "auto", p: 2 }}>
            <Grid
              container
              spacing={0}
              sx={{
                flexWrap: "nowrap",
                width: "max-content",
                padding: 2,
              }}
            >
              <Grid
                item
                sx={{ width: 250, textAlign: "left", fontWeight: 600 }}
              >
                <Typography fontWeight={600}>Descrição</Typography>
              </Grid>

              {[
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro",
              ].map((mes, index) => (
                <React.Fragment key={index}>
                  <Grid
                    item
                    sx={{ width: 120, textAlign: "left", fontWeight: 600 }}
                  >
                    <Typography fontWeight={600}>{mes}</Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{ width: 100, textAlign: "left", fontWeight: 600 }}
                  >
                    <Typography fontWeight={600}>AV%</Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            {consolidacao && consolidacao.length > 0 ? (
              consolidacao.map((indicador, index) => {
                let bgColor = "#fff";
                const length = indicador?.codigo?.length || 0;

                switch (length) {
                  case 1:
                    bgColor = "#d1d1d1";
                    break;
                  case 2:
                    bgColor = "#e8e8e8";
                    break;
                  default:
                    bgColor = "#fff";
                }

                if (indicador?.codigo === "30") {
                  bgColor = "#d9d9d9";
                }

                return (
                  <Grid
                    container
                    key={index}
                    spacing={0}
                    sx={{
                      flexWrap: "nowrap",
                      width: "max-content",
                      background: bgColor,
                      borderBottom: "1px solid #ccc",
                    }}
                    p={2}
                  >
                    <Grid
                      item
                      sx={{
                        width: 250,
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Tooltip
                        title={`${indicador.codigo} - ${indicador.descricao}`}
                        arrow
                      >
                        <Typography
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {`${indicador.codigo} - ${indicador.descricao}`}
                        </Typography>
                      </Tooltip>
                    </Grid>

                    {[
                      "janeiro",
                      "fevereiro",
                      "marco",
                      "abril",
                      "maio",
                      "junho",
                      "julho",
                      "agosto",
                      "setembro",
                      "outubro",
                      "novembro",
                      "dezembro",
                    ].map((mes, idx) => (
                      <React.Fragment key={idx}>
                        <Grid
                          item
                          sx={{
                            width: 120,
                            textAlign: "left",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Typography>
                            {Number(
                              indicador[
                                indicador.tipo.toLowerCase() === "entrada"
                                  ? `entrada_${mes}`
                                  : `saida_${mes}`
                              ] || 0
                            ).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          sx={{
                            width: 100,
                            textAlign: "left",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Typography>
                            {isNaN(
                              parseFloat(
                                indicador[`porcentagem_total_tipo_${mes}`]
                              )
                            )
                              ? "0.00%"
                              : `${parseFloat(
                                  indicador[`porcentagem_total_tipo_${mes}`]
                                ).toFixed(2)}%`}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                );
              })
            ) : (
              <Box
                width={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                p={2}
              >
                Nenhum registro encontrado.
              </Box>
            )}
          </Box>
        ) : null}
        {abaSelecionada && opcaoAbaSelecionada === "consolidacao_resultados" ? (
          <Box sx={{ width: "100%", overflowX: "auto", p: 2 }}>
            <Grid
              container
              spacing={0}
              sx={{
                flexWrap: "nowrap",
                width: "max-content",
                padding: 2,
              }}
            >
              <Grid
                item
                sx={{ width: 250, textAlign: "left", fontWeight: 600 }}
              >
                <Typography fontWeight={600}>Descrição</Typography>
              </Grid>

              {[
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro",
              ].map((mes, index) => (
                <React.Fragment key={index}>
                  <Grid
                    item
                    sx={{ width: 120, textAlign: "left", fontWeight: 600 }}
                  >
                    <Typography fontWeight={600}>{mes}</Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{ width: 100, textAlign: "left", fontWeight: 600 }}
                  >
                    <Typography fontWeight={600}>AV%</Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>

            {demonstrativoResultados && demonstrativoResultados.length > 0 ? (
              demonstrativoResultados.map((indicador, index) => {
                let bgColor = "#fff";
                const length = indicador?.codigo?.length || 0;

                switch (length) {
                  case 1:
                    bgColor = "#d1d1d1";
                    break;
                  case 2:
                    bgColor = "#e8e8e8";
                    break;
                  default:
                    bgColor = "#fff";
                }

                if (indicador?.codigo === "30") {
                  bgColor = "#d9d9d9";
                }

                return (
                  <Grid
                    container
                    key={index}
                    spacing={0}
                    sx={{
                      flexWrap: "nowrap",
                      width: "max-content",
                      background: bgColor,
                      borderBottom: "1px solid #ccc",
                    }}
                    p={2}
                  >
                    <Grid
                      item
                      sx={{
                        width: 250,
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Tooltip
                        title={`${indicador.codigo} - ${indicador.descricao}`}
                        arrow
                      >
                        <Typography
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {`${indicador.codigo} - ${indicador.descricao}`}
                        </Typography>
                      </Tooltip>
                    </Grid>

                    {[
                      "janeiro",
                      "fevereiro",
                      "marco",
                      "abril",
                      "maio",
                      "junho",
                      "julho",
                      "agosto",
                      "setembro",
                      "outubro",
                      "novembro",
                      "dezembro",
                    ].map((mes, idx) => (
                      <React.Fragment key={idx}>
                        <Grid
                          item
                          sx={{
                            width: 120,
                            textAlign: "left",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Typography>
                            {Number(
                              indicador[
                                indicador.tipo.toLowerCase() === "entrada"
                                  ? `entrada_${mes}`
                                  : `saida_${mes}`
                              ] || 0
                            ).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          sx={{
                            width: 100,
                            textAlign: "left",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Typography>
                            {isNaN(
                              parseFloat(
                                indicador[`porcentagem_total_tipo_${mes}`]
                              )
                            )
                              ? "0.00%"
                              : `${parseFloat(
                                  indicador[`porcentagem_total_tipo_${mes}`]
                                ).toFixed(2)}%`}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                );
              })
            ) : (
              <Box
                width={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                p={2}
              >
                Nenhum registro encontrado.
              </Box>
            )}
          </Box>
        ) : null}
        {abaSelecionada &&
        opcaoAbaSelecionada === "consolidacao_setores_produtivos" ? (
          <>
            <Grid container width="100%" my={1} p={2}>
              <Grid item xs={4}>
                <Typography fontWeight={600}>Descrição</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography fontWeight={600}>Agrossilvopastoril</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography fontWeight={600}>Mineração</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography fontWeight={600}>Prestadores de serviço</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography fontWeight={600}>Consolidação</Typography>
              </Grid>
            </Grid>
            {demonstrativoSetorProdutivo &&
            demonstrativoSetorProdutivo.length > 0 ? (
              demonstrativoSetorProdutivo.map((indicador, index) => {
                let bgColor = "#fff";
                const length = indicador?.codigo?.length;

                switch (length) {
                  case 1:
                    bgColor = "#d1d1d1";
                    break;
                  case 2:
                    bgColor = "#e8e8e8";
                    break;
                  default:
                    bgColor = "#fff";
                }

                if (indicador?.codigo === "30") {
                  bgColor = "#d9d9d9";
                }

                const tipo = indicador?.tipo?.toLowerCase();

                return (
                  <Grid
                    container
                    width="100%"
                    p={2}
                    key={index}
                    sx={{ background: bgColor }}
                  >
                    <Grid item xs={4}>
                      <Typography>
                        {`${indicador?.codigo} - ${indicador.descricao}`}
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography>
                        {Number(
                          indicador?.[
                            tipo === "entrada" ? "entrada_3" : "saida_3"
                          ]
                        )
                          ? Number(
                              indicador?.[
                                tipo === "entrada" ? "entrada_3" : "saida_3"
                              ]
                            ).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                          : "R$ 0,00"}
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography>
                        {Number(
                          indicador?.[
                            tipo === "entrada" ? "entrada_4" : "saida_4"
                          ]
                        )
                          ? Number(
                              indicador?.[
                                tipo === "entrada" ? "entrada_4" : "saida_4"
                              ]
                            ).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                          : "R$ 0,00"}
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography>
                        {Number(
                          indicador?.[
                            tipo === "entrada" ? "entrada_5" : "saida_5"
                          ]
                        )
                          ? Number(
                              indicador?.[
                                tipo === "entrada" ? "entrada_5" : "saida_5"
                              ]
                            ).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                          : "R$ 0,00"}
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography>
                        {Number(
                          indicador?.[
                            tipo === "entrada" ? "entrada_6" : "saida_6"
                          ]
                        )
                          ? Number(
                              indicador?.[
                                tipo === "entrada" ? "entrada_6" : "saida_6"
                              ]
                            ).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                          : "R$ 0,00"}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Box
                width={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                p={2}
              >
                Nenhum registro encontrado.
              </Box>
            )}
          </>
        ) : null}
      </Paper>
    </JumboContentLayout>
  );
};

export default ConsolidacaoFcList;
