import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import {
  Box,
  Button,
  Card,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
} from "@mui/material";
import { PermissionContext } from "app/contexts/PermissionContext";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { useMediaQuery } from "beautiful-react-hooks";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { calendarData } from "../calendars/data";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { CardContent } from "@mui/material";
import CalendarWrapper from "../calendars/CalendarWrapper";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AccordionCreateContasAPagar from "app/components/AccordionCreateContasAPagar/AccordionCreateContasAPagar";
import AccordionListaContasAPagar from "app/components/AccordionListaContasAPagar/AccordionListaContasAPagar";
import ApiService from "app/services/config";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import ModalNovaConta from "app/components/ModalNovaConta/ModalNovaConta";
import ActionButton from "app/components/ActionButton/ActionButton";
import QueueIcon from "@mui/icons-material/Queue";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";


const ContasAPagar = () => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [isModalNovaContaAberto, setIsModalNovaContaAberto] = useState(false);
  const [contaAPagarSelecionada, setContaAPagarSelecionada] = useState(null);
  const visualizacaoPadraoCalendario =
    localStorage.getItem("visualizacaoCalendario") || "month";
  const [visualizacaoCalendario, setVisualizacaoCalendario] = useState(
    visualizacaoPadraoCalendario
  );

  const [contasAPagar, setContasAPagar] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [fornecedores, setFornecedores] = useState([]);
  const [filtrosPesquisa, setFiltrosPesquisa] = useState({
    status: "todas",
    fornecedor: "todos",
    categoria: "todas",
  });
  const [selectedContasIds, setSelectedContasIds] = useState([]);
  const [contasAgenda, setContasAgenda] = useState([]);

  const Swal = useSwalWrapper();

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const { theme } = useJumboTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { hasPermission } = useContext(PermissionContext);

  if (!hasPermission("Financeiro", "read")) {
    navigate("/app");
  }

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const { events } = calendarData;
  const today = new Date();
  const currentYear = today.getFullYear();

  const localizer = momentLocalizer(moment);

  const getCategorias = async () => {
    try {
      await ApiService.get("/categoria-contas/all").then((response) => {
        if (response.status === 200) {
          setCategorias(response.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getFornecedores = async () => {
    try {
      await ApiService.get("/fornecedores/all").then((response) => {
        if (response.status === 200) {
          setFornecedores(response.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const clonarObjetosRecorrentes = (conta) => {
    let contasClonadas = [];

    if (
      conta.agenda_conta?.recorrencia === "mensal" &&
      conta.agenda_conta?.fim_recorrencia
    ) {
      const dataInicio = moment(conta.data_vencimento);
      const dataFim = moment(conta.agenda_conta?.fim_recorrencia);

      while (dataInicio.isSameOrBefore(dataFim)) {
        const novaConta = { ...conta };
        novaConta.data_vencimento = dataInicio.toISOString();
        contasClonadas.push(novaConta);
        dataInicio.add(1, "months");
      }
    } else if (
      conta.agenda_conta?.recorrencia === "semanal" &&
      conta.agenda_conta &&
      conta.agenda_conta.dias_agenda_contas_pivot
    ) {
      conta.agenda_conta.dias_agenda_contas_pivot.forEach((diaAgenda) => {
        const novaConta = { ...conta };
        novaConta.data_vencimento = moment(conta.data_vencimento)
          .day(diaAgenda.dias_agenda.dia)
          .toISOString();
        contasClonadas.push(novaConta);
      });
    } else {
      contasClonadas.push(conta);
    }

    return contasClonadas;
  };

  const getContasAPagar = useCallback(async () => {
    try {
      let apiUrl = `/contas-a-pagar?page=${page}&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;
      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      if (filtrosPesquisa.status !== "todas") {
        apiUrl += `&status=${filtrosPesquisa.status}`;
      }
      if (filtrosPesquisa.fornecedor !== "todos") {
        apiUrl += `&fornecedor=${filtrosPesquisa.fornecedor}`;
      }
      if (filtrosPesquisa.categoria !== "todas") {
        apiUrl += `&categoria=${filtrosPesquisa.categoria}`;
      }
      await ApiService.get(apiUrl).then((response) => {
        if (response.status === 200) {
          const initialData = response.data.contas.map((conta) => ({
            ...conta,
            isSelected: selectedContasIds.includes(conta.id),
          }));

          setContasAPagar(initialData);
          setCount(response.data.count);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, filtrosPesquisa]);

  const getContasAPagarAgenda = useCallback(async () => {
    try {
      let apiUrl = `/contas-a-pagar/all`;
      if (searchTerm) {
        apiUrl += `?searchTerm=${searchTerm}`;
      }
      if (filtrosPesquisa.status !== "todas") {
        apiUrl += `&status=${filtrosPesquisa.status}`;
      }
      if (filtrosPesquisa.fornecedor !== "todos") {
        apiUrl += `&fornecedor=${filtrosPesquisa.fornecedor}`;
      }
      if (filtrosPesquisa.categoria !== "todas") {
        apiUrl += `&categoria=${filtrosPesquisa.categoria}`;
      }
      await ApiService.get(apiUrl).then((response) => {
        if (response.status === 200) {
          const contasAjustadasParaAgenda = response.data.map((conta) => {
            const dataVencimento = new Date(conta.data_vencimento);

            const dataAjustada = new Date(
              dataVencimento.getTime() +
                dataVencimento.getTimezoneOffset() * 60000
            );

            return {
              title: conta.descricao,
              id: conta.id,
              allDay: true,
              start: dataAjustada,
              end: dataAjustada,
              status: conta.status_pagamento,
            };
          });

          setContasAgenda(contasAjustadasParaAgenda);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [searchTerm, filtrosPesquisa]);

  const modalAlertDeleteContas = (acao = "unica", id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar a (s) conta (s) selecionadas?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (acao === "unica" && id) {
          handleDeleteContaAPagar(id);
        }
        if (acao === "multiplas" && !id) {
          handleDeleteVariasContasAPagar();
        }
      }
    });
  };

  const handleDeleteContaAPagar = async (id) => {
    try {
      await ApiService.delete(`/contas-a-pagar/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Conta apagada com sucesso!");
          getContasAPagar();
          getContasAPagarAgenda();
        }
      });
    } catch (error) {
      toast("error", "Erro ao apagar conta!");
      console.log(error);
    }
  };

  const handleDeleteVariasContasAPagar = async () => {
    try {
      await ApiService.put(
        `/contas-a-pagar/deletar/varios`,
        selectedContasIds
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Conta apagada com sucesso!");
          getContasAPagar();
          getContasAPagarAgenda();
          setSelectedContasIds([]);
        }
      });
    } catch (error) {
      toast("error", "Erro ao apagar conta!");
      console.log(error);
    }
  };

  const handleOpenModalNovaConta = (event) => {
    setContaAPagarSelecionada(event);
    setIsModalNovaContaAberto(true);
  };

  const handleCloseModalNovaConta = () => {
    setIsModalNovaContaAberto(false);
    setContaAPagarSelecionada(null);
  };

  useEffect(() => {
    getContasAPagar();
    getFornecedores();
    getCategorias();
    getContasAPagarAgenda();
  }, [getContasAPagar, getContasAPagarAgenda]);

  useEffect(() => {
    if (contaAPagarSelecionada) {
      setIsModalNovaContaAberto(true);
    }
  }, [contaAPagarSelecionada]);

  const handleChangeVisualizacaoCalendario = (view) => {
    setVisualizacaoCalendario(view);
    localStorage.setItem("visualizacaoCalendario", view);
  };

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      <Stack
        width="100%"
        height="70px"
        mb={1}
        direction={"row"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        borderBottom={"1px solid #4E5526"}
      >
        <PageHeader
          title={"Contas a pagar"}
          withBorderBottom={false}
          paddingBottom={0}
        />
        <Stack direction="row" gap={0}>
          <ActionButton
            color="green"
            icon={<QueueIcon />}
            title={"Adicionar Nova Conta A Pagar"}
            isSubmitAction={false}
            action={() => {
              setIsModalNovaContaAberto(!isModalNovaContaAberto);
            }}
            width={470}
          />
        </Stack>
      </Stack>
      <ModalNovaConta
        aberto={isModalNovaContaAberto}
        handleClose={handleCloseModalNovaConta}
        getContasAPagar={getContasAPagar}
        getContasAPagarAgenda={getContasAPagarAgenda}
        data={contaAPagarSelecionada}
      />

      <Grid container width="100%" gap={2} my={2}>
        <Grid item xs={2}>
          <SelectAmbiental
            label={"Status"}
            fullWidth={true}
            value={filtrosPesquisa.status}
            onChange={(event) => {
              const status = event.target.value;
              setFiltrosPesquisa((prevFiltros) => ({
                ...prevFiltros,
                status,
              }));
            }}
            isMulti={false}
            options={[
              {
                name: "todas",
                value: "Todas",
              },
              {
                name: "atrasado",
                value: "Atrasadas",
              },
              {
                name: "pendente",
                value: "Pendentes",
              },
              {
                name: "pago",
                value: "Pagas",
              },
            ]}
            nameOptionValue={"name"}
            nameOptionDescription={"value"}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectAmbiental
            label={"Categoria"}
            fullWidth={true}
            value={filtrosPesquisa.categoria}
            onChange={(event) => {
              const categoria = event.target.value;
              setFiltrosPesquisa((prevFiltros) => ({
                ...prevFiltros,
                categoria,
              }));
            }}
            isMulti={false}
            options={[
              {
                id: "descricao",
                descricao: "Todas",
              },
              ...categorias,
            ]}
            nameOptionValue={"name"}
            nameOptionDescription={"descricao"}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          my: 4,
        }}
      >
        <CalendarWrapper>
          <Calendar
            localizer={localizer}
            events={contasAgenda}
            step={60}
            timeslots={8}
            defaultView={visualizacaoCalendario}
            defaultDate={new Date()}
            onSelectEvent={(event) => handleOpenModalNovaConta(event)}
            onView={handleChangeVisualizacaoCalendario}
            eventPropGetter={(event) => {
              let backgroundColor = "";
              let color = "";
              switch (event.status) {
                case "pago":
                  backgroundColor = "#4CAF50";
                  color = "#fff";
                  break;
                case "pendente":
                  backgroundColor = "#FFC107";
                  color = "#0a0a0a";
                  break;
                case "atrasado":
                  backgroundColor = "#F44336";
                  color = "#fff";
                  break;
              }
              let newStyle = {
                backgroundColor,
                color,
                borderRadius: "0px",
                border: "none",
                "&:hover": {
                  backgroundColor: "#005D5F",
                  color: "#fff",
                },
              };
              return {
                className: "",
                style: newStyle,
              };
            }}
            style={{ height: 600 }}
            messages={{
              agenda: "Agenda",
              month: "Mês",
              week: "Semana",
              allDay: "Dia inteiro",
              date: "Data",
              day: "Dia",
              event: "Conta / Descrição",
              next: "Próximo",
              previous: "Anterior",
              today: "Hoje",
              time: "Hora",
              tomorrow: "Amanhã",
              yesterday: "Ontem",
              noEventsInRange: "Nenhum evento",
              showMore: (count) => {
                return <>mostrar + {count}</>;
              },
            }}
            showMultiDayTimes={true}
            toolbar={true}
          />
        </CalendarWrapper>
      </Box>
      <Grid container width="100%" sx={{ my: 1 }}>
        <Grid item xs={12}>
          <AccordionListaContasAPagar
            count={count}
            setCount={setCount}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setSortBy={setSortBy}
            sortBy={sortBy}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            contasAPagar={contasAPagar}
            setContasAPagar={setContasAPagar}
            selectedContasIds={selectedContasIds}
            setSelectedContasIds={setSelectedContasIds}
            handleDeleteContas={modalAlertDeleteContas}
          />
        </Grid>
      </Grid>
    </JumboContentLayout>
  );
};

export default ContasAPagar;
