import { Box } from "@mui/material";
import React from "react";

const AbaDashboard = ({
  children,
  nomeAba,
  isAbaSelecionada,
  setAbaSelecionada,
  setFiltrosPesquisa,
  filtrosPesquisa,
  onClick
}) => {
  return (
    <Box
      onClick={() => {
        setAbaSelecionada(nomeAba);
        setFiltrosPesquisa((prevFiltros) => ({
          ...filtrosPesquisa,
          status: nomeAba,
        }));
        onClick && onClick();
      }}
      sx={{
        width: "100%",
        textAlign: "center",
        height: "35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: isAbaSelecionada ? "#4E5526" : "transparent",
        color: isAbaSelecionada ? "#ffffff" : "#4E5526",
        cursor: "pointer",
        borderRadius: "10px",
        transition: "background-color color ease-in-out 300ms",
        "&:hover": {
          backgroundColor: "#4E5526",
          color: "#ffffff",
        },
        border: isAbaSelecionada ? "none" : "1px solid #4E5526",
      }}
    >
      {children}
    </Box>
  );
};

export default AbaDashboard;
