import InputAmbiental from "@jumbo/components/InputAmbiental";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputLabel,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ActionButton from "../ActionButton/ActionButton";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { useJumboTheme } from "@jumbo/hooks";
import formatMoney from "app/utils/formatMoney";
import ApiService from "app/services/config";
import TreeSelect from "../TreeSelect/TreeSelect";

const validationSchema = {};

const ModalNovoLancamento = ({
  aberto,
  handleClose,
  getLancamentos,
  updateState,
  updateFluxoIndicators,
}) => {
  const id = 0;
  const initialValues = {
    observacao: "",
  };
  const [lancamento, setLancamento] = useState(initialValues);
  const [categories, setCategories] = useState([]);
  const [primaryCategory, setPrimaryCategory] = useState(null);
  const [secondaryCategory, setSecondaryCategory] = useState(null);
  const [tertiaryCategory, setTertiaryCategory] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const planoContaIdsDemoSetorProdutivo = [
    23, 24, 25, 26, 27, 28, 29, 31, 32, 34, 46, 48, 49, 50, 51, 52, 53, 54, 55,
    56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
    75, 76, 77, 78, 79, 87, 89, 90, 91, 92, 93, 95, 96, 97, 98, 99, 100, 101,
    102, 103, 104, 105, 106, 107, 108, 109, 110, 111,
  ];
  const formikRef = useRef();
  const Swal = useSwalWrapper();
  const { theme } = useJumboTheme();

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getCategories = useCallback(async () => {
    try {
      ApiService.get(`/planocontas/categories`)
        .then((response) => {
          setCategories(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleSubmit = async (values, resetForm, setSubmitting) => {
    // Define qual é o último nível de subcategoria, apenas ela é adicionada para ser enviada pelo form
    // const plano_conta_id = isLastCategoryLevel ? tertiaryCategory : secondaryCategory;
    try {
      const formatarDinheiro = (value) => {
        const valueSemPontos = String(value).replace(/\./g, "");
        const valueComPonto = valueSemPontos.replace(",", ".");

        const parsedValue = parseFloat(valueComPonto);

        if (isNaN(parsedValue)) {
          return 0; 
        }

        return parsedValue;
      };

      let allValuesEdit = {
        ...values,
        entrada_caixa: formatarDinheiro(values.entrada_caixa),
        entrada_51387_3: formatarDinheiro(values.entrada_51387_3),
        entrada_51403_9: formatarDinheiro(values.entrada_51403_9),
        saida_caixa: formatarDinheiro(values.saida_caixa),
        saida_51387_3: formatarDinheiro(values.saida_51387_3),
        saida_51403_9: formatarDinheiro(values.saida_51403_9),
      };
      delete allValuesEdit.id;
      delete allValuesEdit.createdAt;
      delete allValuesEdit.updatedAt;
      delete allValuesEdit.deletedAt;

      await ApiService.post("/controlecaixa", allValuesEdit)
        .then(async (response) => {
          toast("success", "Criado com sucesso");
          resetForm();

          if (response.status === 201) {
            await updateState();
            updateFluxoIndicators();
            handleClose();
          }
        })
        .catch((error) => {
          let message = error.response.data.message[0];
          toast("error", message);

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      toast("error", "Ocorreu um erro ao tentar cadastrar o lançamento.");
    }
  };

  useEffect(() => {
    getCategories();
  }, [aberto]);

  return (
    <Dialog
      open={aberto}
      onClose={handleClose}
      sx={{
        overflow: "scroll",
      }}
      PaperProps={{
        style: {
          width: "600px",
          maxWidth: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          padding: 20,
          height: "auto",
        },
      }}
    >
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({
          values,
          isSubmitting,
          setSubmitting,
          handleChange,
          updateInputValue,
          setFieldValue,
          errors,
          handleBlur,
          resetForm,
          touched,
          setTouched,
        }) => (
          <Form style={{ width: "100%" }}>
            <DialogContent
              style={{
                width: "100%",
                overflow: "visible",
              }}
            >
              <Grid container width="100%" spacing={1}>
                <Grid item xs={6}>
                  <InputLabel
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      marginBottom: 12,
                    }}
                    htmlFor="grouped-native-select"
                  >
                    Categoria:
                  </InputLabel>
                  <TreeSelect
                    categories={categories}
                    setFieldValue={setFieldValue}
                    defaultValue={values.plano_conta_id}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputAmbiental
                    type="date"
                    label={"Data de Lançamento:"}
                    name={"data"}
                    onChange={(event) => {
                      setFieldValue("data", event.target.value);
                    }}
                    value={values.data}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>

              {planoContaIdsDemoSetorProdutivo.includes(
                values.plano_conta_id
              ) && (
                <Grid container width="100%" spacing={1} my={1}>
                  <Grid item xs={6}>
                    <SelectAmbiental
                      label={"Sub Categoria (Opcional)"}
                      name={"sub_plano_conta_id"}
                      value={values.sub_plano_conta_id}
                      onChange={(event) => {
                        setFieldValue(
                          "sub_plano_conta_id",
                          Number(event.target.value)
                        );
                      }}
                      fullWidth={true}
                      options={[
                        {
                          numero: 3,
                          titulo: "Agrossilvopastoril",
                        },
                        {
                          numero: 4,
                          titulo: "Mineração",
                        },
                        {
                          numero: 5,
                          titulo: "Prestadores de serviços",
                        },
                      ]}
                      nameOptionValue={"numero"}
                      nameOptionDescription={"titulo"}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container width="100%" spacing={1} my={1}>
                <Grid item xs={12}>
                  <InputAmbiental
                    type="text"
                    label={"Observação:"}
                    name={"observacao"}
                    onChange={(event) => {
                      setFieldValue("observacao", event.target.value);
                    }}
                    value={values.observacao}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>

              <Grid container width="100%" spacing={1} my={1}>
                <Grid item xs={6}>
                  <InputAmbiental
                    type="text"
                    label={"Entrada Caixa:"}
                    labelColor="#4E5526"
                    name={"entrada_caixa"}
                    hasMask={true}
                    maskType={"dinheiro"}
                    onChange={(event, value) => {
                      setFieldValue("entrada_caixa", value);
                    }}
                    value={values.entrada_caixa}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputAmbiental
                    type="text"
                    label={"Saída Caixa:"}
                    labelColor="#CA0606"
                    name={"saida_caixa"}
                    hasMask={true}
                    maskType={"dinheiro"}
                    onChange={(event, value) => {
                      setFieldValue("saida_caixa", value);
                    }}
                    value={values.saida_caixa}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
              <Grid container width="100%" spacing={1} my={1}>
                <Grid item xs={6}>
                  <InputAmbiental
                    type="text"
                    label={"Entrada 51387_3:"}
                    labelColor="#4E5526"
                    name={"entrada_51387_3"}
                    hasMask={true}
                    maskType={"dinheiro"}
                    onChange={(event, value) => {
                      setFieldValue("entrada_51387_3", value);
                    }}
                    value={values.entrada_51387_3}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputAmbiental
                    type="text"
                    label={"Saída Caixa 51387_3:"}
                    labelColor="#CA0606"
                    name={"saida_51387_3"}
                    hasMask={true}
                    maskType={"dinheiro"}
                    onChange={(event, value) => {
                      setFieldValue("saida_51387_3", value);
                    }}
                    value={values.saida_51387_3}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingRight: 40,
              }}
            >
              <ActionButton
                title={id ? "Atualizar" : "Criar"}
                to={null}
                color="blue"
                icon={<BorderColorIcon sx={{ fontSize: "16px" }} />}
                isSubmitAction={true}
                action={() => {
                  handleSubmit(values, resetForm, setSubmitting);
                }}
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ModalNovoLancamento;
