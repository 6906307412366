import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  Toolbar,
  useMediaQuery,
  Chip,
  TextField,
  InputAdornment,
  Typography,
  Card,
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { LoadingButton } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { PermissionContext } from "app/contexts/PermissionContext";
import QueueIcon from "@mui/icons-material/Queue";
import ActionButton from "app/components/ActionButton/ActionButton";
import SearchIcon from "@mui/icons-material/Search";
import BotoesExportarDados from "app/components/BotoesExportarDados";
import PaginationComponent from "app/components/Pagination/Pagination";

const PlanoContasList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const [planoContas, setPlanoContas] = useState([]);
  const [selectedTiposServicoIds, setSelectedTiposServicoIds] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [inputValue, setInputValue] = useState("");

  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  if (!hasPermission("Financeiro", "read")) {
    navigate("/app");
  }

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
    });
  };

  const modalAlert = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deletePlanoConta(id);
      }
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const deletePlanoConta = useCallback(async (id) => {
    try {
      ApiService.delete(`/planocontas/${id}`)
        .then((response) => {
          toast("success", "Removido com sucesso");
          getPlanoContas();
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getPlanoContas = useCallback(async () => {
    try {
      let apiUrl = `planocontas/?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }

      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.planoContas.map((data) => {
            let planoMaeDesc = data.plano_conta_id
              ? response.data.categories.find(
                  (category) => category.id === data.plano_conta_id
                )?.plano_mae_desc
              : null;

            if (data.id === 1 || String(data.plano_mae_desc).toUpperCase() === "TOTAL DE ENTRADAS") {
              planoMaeDesc = "TOTAL DE ENTRADAS";
            }

            if (data.id === 23 || String(data.plano_mae_desc).toUpperCase() === "TOTAL DE SAÍDAS") {
              planoMaeDesc = "TOTAL DE SAÍDAS";
            }

            return {
              ...data,
              isSelected: selectedTiposServicoIds.includes(data.id),
              plano_mae_desc: planoMaeDesc,
              plano_mae_id: data.plano_conta_id
                ? response.data.categories.find(
                    (category) => category.id === data.plano_conta_id
                  ).plano_mae_id
                : null,
            };
          });

          setPlanoContas(initialData);
          setCount(response.data.total);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, selectedTiposServicoIds]);

  useEffect(() => {
    getPlanoContas();
  }, [getPlanoContas]);

  // const handleCheckboxChange = (planoContaId) => {
  //   setPlanoContas((prevTipoServico) =>
  //     prevTipoServico.map((tipoServico) =>
  //       tipoServico.id === planoContaId
  //         ? { ...tipoServico, isSelected: !tipoServico.isSelected }
  //         : tipoServico
  //     )
  //   );

  //   setSelectedTiposServicoIds((prevSelectedIds) => {
  //     if (prevSelectedIds.includes(planoContaId)) {
  //       return prevSelectedIds.filter((id) => id !== planoContaId);
  //     } else {
  //       return [...prevSelectedIds, planoContaId];
  //     }
  //   });
  // };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "codigo",
      label: "Código",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "plano_mae_desc",
      label: "Categoria",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const categories = tableMeta.rowData[2];
          const explodedCategories =
            typeof categories == "string" ? categories.split(",") : [];
          const colors = ["#005D5F", "#007d80", "#00afb3", "#00c8cc"];

          return explodedCategories.map((element, index) => {
            return (
              <Chip
                color="primary"
                style={{ backgroundColor: colors[index], margin: 3 }}
                label={element}
              />
            );
          });
        },
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const planoContaId = tableMeta.rowData[0];
          return (
            <Box display={"flex"} gap={"1rem"}>
              <Link
                to={`/app/editar-plano-conta/${planoContaId}`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  color="info"
                  variant="contained"
                  size="small"
                  disableElevation
                >
                  <VisibilityIcon />
                </Button>
              </Link>
              <Button
                color="error"
                variant="contained"
                size="small"
                onClick={() => modalAlert(planoContaId)}
              >
                <DeleteIcon />
              </Button>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    // searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customToolbar: ({ displayData }) => (
      <>
        {selectedTiposServicoIds.length > 0 ? (
          <Toolbar>
            <Box
              display={"flex"}
              gap={"1rem"}
              justifyContent={"flex-end"}
              width={"100%"}
            ></Box>
          </Toolbar>
        ) : null}
      </>
    ),
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      <Stack
        width="100%"
        height={"70px"}
        mb={1}
        direction={"row"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        borderBottom={"1px solid #4E5526"}
      >
        <PageHeader
          title={"Plano de Contas"}
          withBorderBottom={false}
          paddingBottom={0}
        />
        <Stack direction="row" gap={0}>
          <ActionButton
            color="green"
            icon={<QueueIcon />}
            title={"Novo Plano de Contas"}
            isSubmitAction={false}
            to={"/app/novo-plano-conta"}
            width={470}
          />
        </Stack>
      </Stack>

      <Grid container width="100%" my={1}>
        <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <TextField
              variant="standard"
              placeholder="Pesquisar..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch(inputValue);
                }
              }}
              InputProps={{
                sx: {
                  borderRadius: 2,
                  border: "1px solid #4E5526",
                  paddingLeft: 2,
                  height: "36px",
                  "&:hover": { borderColor: "#3B4220" },
                  "&.Mui-focused": { borderColor: "#4E5526" },
                  color: "#4E5526",
                  "&::placeholder": { color: "red", opacity: 1 },
                  width: 350,
                },
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      sx={{
                        backgroundColor: "#4E5526",
                        borderRadius: 2,
                        minWidth: "36px",
                        height: "36px",
                        padding: 0,
                        "&:hover": { backgroundColor: "#3B4220" },
                      }}
                      onClick={() => handleSearch(inputValue)}
                    >
                      <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
          <BotoesExportarDados
            titulo={"Plano de Contas"}
            dados_planilha={planoContas}
            nome_arquivo_planilha={"plano_contas"}
            colunas_planilha={[
              "id",
              "codigo",
              "descricao",
              "plano_mae_id",
              "plano_mae_desc",
            ]}
          />
        </Grid>
      </Grid>

      <Grid
        container
        width="100%"
        alignItems={"center"}
        my={2}
        p={2}
        spacing={2}
      >
        <Grid
          item
          xs={3}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            fontWeight={600}
            fontSize={18}
            style={{ color: "#212529" }}
          >
            Código
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            fontWeight={600}
            fontSize={18}
            style={{ color: "#212529" }}
          >
            Descrição
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          display="flex"
        >
          <Typography
            fontWeight={600}
            fontSize={18}
            style={{ color: "#212529" }}
          >
            Categoria
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            fontWeight={600}
            fontSize={18}
            style={{ color: "#212529" }}
          >
            Ações
          </Typography>
        </Grid>
      </Grid>

      {planoContas.length > 0 && planoContas ? (
        planoContas?.map((plano, index) => (
          <Card
            width="100%"
            sx={{ my: 1, minHeight: "50px" }}
            key={`${plano.id}-${index}`}
          >
            <Grid
              container
              width="100%"
              alignItems={"center"}
              p={2}
              spacing={2}
            >
              <Grid
                item
                xs={3}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography
                  fontWeight={600}
                  fontSize={16}
                  style={{ color: "#212529" }}
                >
                  {plano?.codigo ?? "N/I"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  fontWeight={400}
                  fontSize={16}
                  style={{ color: "#212529" }}
                >
                  {plano?.descricao ?? "N/I"}
                </Typography>
              </Grid>
              <Grid item xs={3} display="flex" flexWrap="wrap" gap={1}>
                {plano.plano_mae_desc && plano.plano_mae_desc.trim() !== "" ? (
                  plano.plano_mae_desc.split(",").map((categoria, index) => {
                    const colors = ["#005D5F", "#007d80", "#00afb3", "#00c8cc"];
                    return (
                      <Chip
                        key={index}
                        color="primary"
                        style={{
                          backgroundColor: colors[index % colors.length],
                          margin: 3,
                        }}
                        label={categoria.trim()}
                      />
                    );
                  })
                ) : (
                  <Chip
                    key={"unica-categoria"}
                    color="primary"
                    style={{
                      backgroundColor: "black",
                      margin: 3,
                    }}
                    label={plano?.plano_mae_desc}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={3}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={2}
              >
                <VisibilityIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/app/editar-plano-conta/${plano.id}`);
                  }}
                />
                <DeleteIcon
                  sx={{ cursor: "pointer", color: "#C84E4D" }}
                  onClick={() => {
                    modalAlert(plano.id);
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        ))
      ) : (
        <Box width="100%" p={4} textAlign={"center"}>
          Nenhum plano de conta cadastrado.
        </Box>
      )}
      <PaginationComponent
        page={page}
        setPage={setPage}
        count={count}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </JumboContentLayout>
  );
};

export default PlanoContasList;
