import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Fade,
  Modal,
  Paper,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ApiService from "app/services/config";
import CurrencyInput from "react-currency-input-field";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";
import ActionButton from "../ActionButton/ActionButton";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from '@mui/icons-material/Logout';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  currencyInput: {
    width: "100%",
    height: 50,
    padding: "10px 14px",
    fontSize: "1rem",
    borderRadius: 4,
    border: "1px solid #ced4da",
    backgroundColor: "#fff",
    boxSizing: "border-box",
  },
};

const validationSchema = yup.object().shape({
  contratoId: yup.number().required("É preciso fornecer o ID do contrato"),
  valor: yup.number("Insira um número válido").required("Campo obrigatório"),
  numeroParcelas: yup
    .number("Insira um número válido")
    .required("Campo obrigatório"),
  descricao: yup.string().optional(),
});

const NUMERO_TOTAL_PARCELMENTO = 12;

const ModalRenegociarContrato = ({
  aberto,
  handleClose,
  contratoId,
  atualizarValorRenegociacao,
  valorAtualContrato,
  setValorAtualRenegociacao
}) => {
  const initialValues = {
    contratoId: "",
    valor: 0,
    numeroParcelas: 1,
    descricao: "",
  };
  const [pagamentoContrato, setPagamentoContrato] = useState(initialValues);
  const [valorMoedaFormatada, setValorMoedaFormatada] = useState(
    initialValues.valor
  );

  const [modalAberto, setModalAberto] = useState(aberto);
  const [idContrato, setIdContrato] = useState();
  useEffect(() => {
    setModalAberto(aberto);
  }, [aberto]);

  const Swal = useSwalWrapper();

  useEffect(() => {
    setIdContrato(contratoId);
    setPagamentoContrato((prevPagamentoContrato) => ({
      ...prevPagamentoContrato,
      contratoId: contratoId,
    }));
  }, [contratoId]);

  const toast = (variant, message, type = false) => {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      icon: type ? "success" : "error",
      title: message,
      didOpen: (toast) => {
        toast.style.zIndex = 10000;
      },
      timer: 3000,
    });
  };

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      await ApiService.post("/renegociacaocontrato", values).then(
        (response) => {
          if (response.status === 201) {
            const novaRenegociacao = response.data;
            atualizarValorRenegociacao(novaRenegociacao, "adicao");
            console.log(`Valor: ${response.data.valor}`)
            setValorAtualRenegociacao(response.data?.valor);
            handleClose();
            toast(null, "Inserido com sucesso!", "success");
          }
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  return (
    <Paper>
      <Modal
        open={modalAberto}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Fade in={modalAberto}>
          <Box sx={{...style, borderRadius: 4, padding: 5 }}>
            <Typography variant="h2" component="h2" gutterBottom fontWeight={600}>
              Renegociar
            </Typography>
            <Divider sx={{ backgroundColor: "#4E5526" }} />
            <Box width={1} mt={3}>
              <Formik
                enableReinitialize
                initialValues={pagamentoContrato}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Box width={1} mt={3}>
                      <Typography variant="h5" component="h5" gutterBottom fontWeight={600}>
                        Valor atual do contrato:{" "}
                        {Number(valorAtualContrato).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </Typography>
                    </Box>
                    <Box width={1} mt={3}>
                      <InputLabel id="valor" sx={{ marginBottom: 1 }}>Valor novo</InputLabel>

                      <CurrencyInput
                        style={style.currencyInput}
                        id="valor"
                        name="valor"
                        prefix={"R$ "}
                        decimalsLimit={2}
                        decimalSeparator=","
                        groupSeparator="."
                        step={0.01}
                        defaultValue={values.valor}
                        placeholder="Valor"
                        onValueChange={(value, name, values) => {
                          if (
                            value === undefined ||
                            value === null ||
                            value === ""
                          ) {
                            setFieldValue("valor", 0);
                          } else {
                            const valorFormatado = Number(
                              value.replace(/[^0-9,.]/g, "").replace(",", ".")
                            );
                            setFieldValue("valor", valorFormatado);
                          }
                        }}
                      />

                      <ErrorMessage
                        name="valor"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Box>
                    {console.log(errors)}
                    <Box width={1} mt={3}>
                      <InputLabel id="numeroParcelasSelect" sx={{ marginBottom: 1 }}>
                        Número de parcelas
                      </InputLabel>

                      <SelectAmbiental
                        labelId="numeroParcelasSelect"
                        name="numeroParcelas"
                        value={values.numeroParcelas}
                        onChange={(event) => {
                          setFieldValue("numeroParcelas", Number(event.target.value));
                        }}
                        fullWidth
                        options={Array.from({ length: NUMERO_TOTAL_PARCELMENTO }, (_, index) => ({
                          value: Number(index + 1),
                          label: `${index + 1}x`,
                        }))}
                        valueNumber
                        nameOptionDescription={"label"}
                        nameOptionValue={"value"}
                        style={{ height: 50 }}
                      >
                      </SelectAmbiental>
                      <ErrorMessage
                        name="numeroParcelas"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Box>
                    <Box width={1} mt={3}>
                      <InputLabel id="descricao" sx={{ marginBottom: 1 }}>Descrição</InputLabel>

                      <TextField
                        name="descricao"
                        variant="outlined"
                        onChange={handleChange}
                        multiline
                        rows={4}
                        fullWidth
                        sx={{
                          borderRadius: "10px",
                          border: "1px solid #4E5526"
                        }}
                      />
                    </Box>
                    <Box mt={3} display="flex" justifyContent="flex-end" gap={1}>
                      <ActionButton title="Atualizar" icon={<EditIcon />} action={handleSubmit} />
                      <ActionButton title="Fechar" color="red" icon={<LogoutIcon />} action={handleClose} />
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Paper>
  );
};

export default ModalRenegociarContrato;
