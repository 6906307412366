import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fade,
  Modal,
  Paper,
  Box,
  FormHelperText,
  Chip,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ApiService from "app/services/config";
import { ErrorMessage, Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import React, { useEffect, useState, useCallback, useRef } from "react";
import * as yup from "yup";
import MoneyInput from "../MoneyInput/MoneyInput";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";
import ActionButton from "../ActionButton/ActionButton";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import InputAmbiental from "@jumbo/components/InputAmbiental";

const validationSchema = yup.object().shape({
  descricao: yup.string().required("Campo obrigatório"),
  valor: yup.string().required("Campo obrigatório").typeError("Valor inválido"),
  status_pagamento: yup.string().required("Campo obrigatório"),
  categoria: yup
    .number("Campo obrigatório")
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
  data_vencimento: yup
    .date("Campo obrigatório")
    .required("Campo obrigatório")
    .typeError("Data inválida"),
  horario: yup.string().typeError("Valor inválido"),
  status_agendamento: yup.string().nullable(),
  recorrencia: yup
    .string("Campo obrigatório")
    .required("Campo obrigatório")
    .typeError("Campo obrigatório"),
  inicio_recorrencia: yup
    .date("Campo obrigatório")
    .typeError("Data inválida")
    .when("recorrencia", {
      is: (value) => value !== "nenhuma",
      then: yup.date().required("Campo obrigatório"),
      otherwise: yup.date().nullable(),
    }),
  fim_recorrencia: yup
    .date("Campo obrigatório")
    .typeError("Data inválida")
    .when("recorrencia", {
      is: (value) => value !== "nenhuma",
      then: yup.date().required("Campo obrigatório"),
      otherwise: yup.date().nullable(),
    }),
  dias: yup
    .array()
    .of(
      yup.object().shape({
        dia: yup.string().when("recorrencia", {
          is: "semanal",
          then: yup.string().required("Escolha pelo menos um dia"),
        }),
        id: yup.number(),
      })
    )
    .when("recorrencia", {
      is: "semanal",
      then: yup
        .array()
        .min(1, "Escolha pelo menos um dia")
        .required("Campo obrigatório"),
      otherwise: yup.array().nullable(),
    }),
  usuario: yup.number("Campo obrigatório").required("Campo obrigatório"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const DIAS_SEMANA = {
  0: "Domingo",
  1: "Segunda-feira",
  2: "Terça-feira",
  3: "Quarta-feira",
  4: "Quinta-feira",
  5: "Sexta-feira",
  6: "Sábado",
};

const ModalNovaConta = ({
  aberto,
  handleClose,
  data,
  getContasAPagar,
  getContasAPagarAgenda,
}) => {
  // console.log('data', data)
  const id = data?.id;
  const [modalAberto, setModalAberto] = useState(aberto);
  const [categorias, setCategorias] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const initialValues = {
    descricao: data?.title ?? "",
    valor: data?.valor ?? 0,
    status_pagamento: data?.status ?? "",
    categoria: data?.categoria ?? "",
    data_vencimento: data?.data_vencimento ?? "",
    beneficiario: data?.beneficiario ?? "",
    horario: "",
    status_agendamento: "ativo",
    recorrencia: "nenhuma",
    inicio_recorrencia: "",
    fim_recorrencia: "",
    usuario: "",
    dias: [],
    status_pagamento_conta: "pendente",
    descricao_conta: "",
    valor_conta: 0,
    categoria_conta: "",
    beneficiario_conta: "",
  };
  const [contaAPagar, setContaAPagar] = useState(initialValues);
  const [agendamentoInicial, setAgendamentoInicial] = useState({});
  const [idConta, setIdConta] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);
  const formikRef = useRef();
  // console.log('ModalNovaConta')
  // console.log('data', data)
  // console.log('categorias', categorias)

  const Swal = useSwalWrapper();

  const navigate = useNavigate();

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getCategorias = async () => {
    try {
      await ApiService.get("/categoria-contas/all").then((response) => {
        if (response.status === 200) {
          setCategorias(response.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateCategoria = useCallback(async (nome) => {
    // console.log('handleCreateCategoria', nome)
    const response = await ApiService.post("/categoria-contas", { nome })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getCategorias();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleSubmit = async (values, resetForm) => {
    try {
      if (Object.entries(formikRef.current.errors).length > 0) {
        toast(
          "error",
          "Existem campos inválidos ou que precisam ser preenchidos!"
        );
        return;
      }
      const contaValues = {
        descricao: id ? values.descricao_conta : values.descricao,
        status_pagamento: values.status_pagamento,
        categoria: id ? values.categoria_conta : values.categoria,
        data_vencimento: values.data_vencimento,
        beneficiario: id ? values.beneficiario_conta : values.beneficiario,
        valor: id
          ? parseFloat(String(values.valor_conta).replace(",", "."))
          : parseFloat(String(values.valor).replace(",", ".")),
      };

      let agendaValues = {
        descricao: values.descricao,
        data: values.data_vencimento,
        horario: values.horario,
        status_agendamento: values.status_agendamento,
        recorrencia: values.recorrencia,
        inicio_recorrencia: values.inicio_recorrencia,
        fim_recorrencia: values.fim_recorrencia,
        usuario: values.usuario,
        dias: values.dias,
        status_pagamento_conta: id
          ? values.status_pagamento_conta
          : values.status_pagamento,
        descricao_conta: id ? values.descricao_conta : values.descricao,
        categoria_conta: id ? values.categoria_conta : values.categoria,
        beneficiario_conta: id
          ? values.beneficiario_conta
          : values.beneficiario,
        valor_conta: id
          ? parseFloat(String(values.valor_conta).replace(",", "."))
          : parseFloat(String(values.valor).replace(",", ".")),
      };

      const valoresAgendaDiferentes = Object.keys(agendamentoInicial).some(
        (key) => {
          console.log(key);
          return agendamentoInicial[key] !== agendaValues[key];
        }
      );

      if (id) {
        await ApiService.put(`/contas-a-pagar/${id}`, contaValues).then(
          async (response) => {
            if (response.status === 200) {
              if (valoresAgendaDiferentes) {
                const responseAgenda = await ApiService.put(
                  `/agenda-contas/${response.data?.agenda_conta?.id}`,
                  {
                    ...agendaValues,
                    conta_id: id,
                  }
                );
                if (responseAgenda.status !== 200) {
                  throw new Error("Erro ao atualizar agenda");
                }
              }
              toast("success", "Atualizado com sucesso!", "success");
              getContasAPagar();
              getContasAPagarAgenda();
              handleClose();
              resetForm();
            } else {
              throw new Error("Erro ao atualizar conta");
            }
          }
        );
      } else {
        const responseConta = await ApiService.post(
          "/contas-a-pagar",
          contaValues
        );

        if (responseConta.status === 201) {
          const contaId = responseConta.data.id;

          const responseAgenda = await ApiService.post(`/agenda-contas`, {
            ...agendaValues,
            conta_id: contaId,
          });

          if (responseAgenda?.status === 201) {
            toast("success", "Criado com sucesso!", "success");
            getContasAPagar();
            getContasAPagarAgenda();
            handleClose();
            resetForm();
          } else {
            throw new Error("Erro ao criar agenda");
          }
        } else {
          throw new Error("Erro ao criar conta");
        }
      }
    } catch (error) {
      console.error(error);
      toast("error", "Ocorreu um erro ao criar a conta!");
    }
  };

  const getUsuarios = async () => {
    try {
      await ApiService.get("/users/colaboradores").then((response) => {
        if (response.status === 200) {
          setUsuarios(response.data);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDayChange = (value) => {
    if (selectedDays.some((dia) => dia.dia === value[value.length - 1])) {
      const index = selectedDays.findIndex(
        (dia) => dia.dia === value[value.length - 1]
      );
      const newSelectedDays = [
        ...selectedDays.slice(0, index),
        ...selectedDays.slice(index + 1),
      ];
      setSelectedDays(newSelectedDays);
      formikRef.current.setFieldValue("dias", newSelectedDays);
      if (selectedDays[index].id) {
        //handleDeleteDiaAgenda(selectedDays[index].id);
      }
    } else {
      const newSelectedDays =
        typeof value === "string"
          ? value.split(",")
          : value.map((dia) => (typeof dia === "object" ? dia : { dia }));

      setSelectedDays(newSelectedDays);
      formikRef.current.setFieldValue("dias", newSelectedDays);
    }
  };

  const getContaAPagar = async () => {
    try {
      await ApiService.get(`/contas-a-pagar/${data?.id}`).then((response) => {
        if (response.status === 200) {
          const { agenda_conta } = response.data;
          let dias = agenda_conta.dias_agenda_contas_pivot
            .filter((dia) => dia.dias_agenda !== null)
            .map((dia) => {
              if (
                dia?.dias_agenda?.id &&
                dia?.dias_agenda.dia &&
                dia?.dias_agenda !== undefined &&
                dia?.dias_agenda !== null
              ) {
                return {
                  dia: dia?.dias_agenda?.dia,
                  id: dia?.dias_agenda?.id,
                };
              }
            });

          const conta = {
            categoria:
              response.data?.contas_categorias?.[0]?.categoria?.id || "",
            data_vencimento: moment
              .utc(response.data.data_vencimento)
              .format("YYYY-MM-DD"),
            descricao: response.data.descricao,
            beneficiario: response.data.beneficiario,
            status_pagamento: response.data.status_pagamento,
            valor: response.data.valor,
            inicio_recorrencia:
              agenda_conta.recorrencia !== "nenhuma"
                ? moment
                    .utc(agenda_conta.inicio_recorrencia)
                    .format("YYYY-MM-DD")
                : "",
            fim_recorrencia:
              agenda_conta.recorrencia !== "nenhuma"
                ? moment.utc(agenda_conta.fim_recorrencia).format("YYYY-MM-DD")
                : "",
            horario: agenda_conta.horario,
            recorrencia: agenda_conta.recorrencia,
            status_agendamento: agenda_conta.status_agendamento,
            usuario: agenda_conta.usuario_id,
            dias,
            descricao_conta: response.data?.descricao,
            valor_conta: response.data?.valor,
            categoria_conta:
              response.data?.contas_categorias?.[0]?.categoria?.id || "",
            beneficiario_conta: response.data?.beneficiario,
          };
          setContaAPagar(conta);
          setAgendamentoInicial({
            horario: agenda_conta.horario,
            recorrencia: agenda_conta.recorrencia,
            status_agendamento: agenda_conta.status_agendamento,
            usuario: agenda_conta.usuario_id,
            dias,
            inicio_recorrencia: moment
              .utc(agenda_conta.inicio_recorrencia)
              .format("YYYY-MM-DD"),
            fim_recorrencia: moment
              .utc(agenda_conta.fim_recorrencia)
              .format("YYYY-MM-DD"),
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const modalAlert = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar a conta selecionada?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleDeleteConta();
      }
    });
  };

  const handleDeleteConta = async () => {
    try {
      await ApiService.delete(`/contas-a-pagar/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Deletado com sucesso!", "success");
          getContasAPagar();
          getContasAPagarAgenda();
          handleClose();
        }
      });
    } catch (error) {
      toast("error", "Erro ao apagar conta!");
      console.log(error);
    }
  };

  useEffect(async () => {
    setModalAberto(aberto);
    setIdConta(data?.id);
    if (aberto) {
      await getContaAPagar();
    }
  }, [aberto, data]);

  useEffect(() => {
    getCategorias();
  }, []);

  useEffect(async () => {
    if (idConta) {
    }
  }, [data]);

  useEffect(() => {
    getUsuarios();
    return () => {
      setContaAPagar(initialValues);
    };
  }, [aberto]);

  return (
    <Dialog
      open={aberto}
      onClose={handleClose}
      sx={{
        overflow: "scroll",
      }}
      PaperProps={{
        style: {
          width: "800px",
          maxWidth: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          padding: 20,
          height: "auto",
        },
      }}
    >
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={contaAPagar}
        validationSchema={validationSchema}
        validateOnBlur
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          updateInputValue,
          setFieldValue,
          errors,
          handleBlur,
          resetForm,
          touched,
          setTouched,
        }) => (
          <Form style={{ width: "100%" }}>
            <DialogContent
              style={{
                width: "100%",
                overflow: "visible",
              }}
            >
              <Grid container width="100%" spacing={1}>
                <Grid item xs={9}>
                  <InputAmbiental
                    name="descricao"
                    fullWidth={true}
                    label={"Descrição:"}
                    value={id ? values.descricao_conta : values.descricao}
                    onChange={(event) => {
                      const descricao = event.target.value;
                      id
                        ? setFieldValue("descricao_conta", descricao)
                        : setFieldValue("descricao", descricao);
                    }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SelectAmbiental
                    name="categoria_conta"
                    fullWidth={true}
                    label={"Categoria:"}
                    value={categorias.find(
                      (categoria) =>
                        categoria.id ===
                        (id ? values.categoria_conta : values.categoria)
                    )}
                    options={categorias}
                    onChange={(event) => {
                      id
                        ? setFieldValue(
                            `categoria`,
                            event ? Number(event.target.value) : ""
                          )
                        : setFieldValue(
                            `categoria`,
                            event ? Number(event.target.value) : ""
                          );
                    }}
                    nameOptionDescription={"nome"}
                    nameOptionValue={"id"}
                    required={true}
                  />
                </Grid>
              </Grid>
              <Grid container width="100%" spacing={1} my={1}>
                <Grid item xs={4}>
                  <InputAmbiental
                    fullWidth={true}
                    label={"Data:"}
                    type="date"
                    name="data_vencimento"
                    value={
                      values.data_vencimento
                        ? moment
                            .utc(values?.data_vencimento)
                            .format("YYYY-MM-DD")
                        : null
                    }
                    onChange={(event) => {
                      setFieldValue("data_vencimento", event.target.value);
                    }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormGroup>
                    <InputLabel
                      sx={{
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Horário:
                    </InputLabel>
                    <TextField
                      name="horario"
                      type="time"
                      value={values.horario}
                      onChange={handleChange}
                      fullWidth
                      placeholder="Horário"
                      InputProps={{
                        sx: {
                          height: "41px",
                          display: "flex",
                          alignItems: "center",
                        },
                      }}
                      style={{
                        width: "100%",
                        marginTop: "10px",
                        border: "1px solid #4E5526",
                        borderRadius: "10px",
                        overflow: "hidden",
                        outline: "none",
                      }}
                      required={true}
                    />

                    <FormHelperText>
                      Caso tenha algum horário específico, informar neste campo
                      (OPCIONAL).
                    </FormHelperText>
                  </FormGroup>
                </Grid>
                <Grid item xs={4}>
                  <SelectAmbiental
                    label={"Recorrência:"}
                    name="recorrencia"
                    fullWidth={true}
                    value={values.recorrencia}
                    nameOptionDescription={"descricao"}
                    nameOptionValue={"nome"}
                    options={[
                      {
                        nome: "nenhuma",
                        descricao: "Nenhuma",
                      },
                      {
                        nome: "diaria",
                        descricao: "Diária",
                      },
                      {
                        nome: "semanal",
                        descricao: "Semanal",
                      },
                      {
                        nome: "mensal",
                        descricao: "Mensal",
                      },
                      {
                        nome: "anual",
                        descricao: "Anual",
                      },
                    ]}
                    onChange={(event) => {
                      setFieldValue("recorrencia", event.target.value);
                    }}
                    required={true}
                  />
                </Grid>
              </Grid>
              <Grid container width="100%" spacing={1} my={1}>
                <Grid item xs={4}>
                  <InputAmbiental
                    fullWidth={true}
                    label={"Valor:"}
                    hasMask={true}
                    maskType={"dinheiro"}
                    name="valor"
                    adornment={"R$"}
                    value={id ? values.valor_conta : values.valor}
                    onChange={(event, value) => {
                      if (
                        value === undefined ||
                        value === null ||
                        value === ""
                      ) {
                        setFieldValue(id ? "valor_conta" : "valor", 0);
                      } else {
                        setFieldValue(id ? "valor_conta" : "valor", value);
                      }
                    }}
                    required={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputAmbiental
                    name={id ? values.beneficiario_conta : values.beneficiario}
                    label={"Beneficiário:"}
                    onChange={(event) => {
                      setFieldValue(
                        id ? "beneficiario_conta" : "beneficiario",
                        event.target.value
                      );
                    }}
                    fullWidth={true}
                    value={values.beneficiario}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectAmbiental
                    label={"Status do pagamento:"}
                    name={"status_pagamento"}
                    fullWidth={true}
                    value={
                      values.status_pagamento
                    }
                    nameOptionDescription={"descricao"}
                    nameOptionValue={"nome"}
                    options={[
                      {
                        nome: "pendente",
                        descricao: "Pendente",
                      },
                      {
                        nome: "pago",
                        descricao: "Pago",
                      },
                      {
                        nome: "atrasado",
                        descricao: "Atrasada",
                      },
                    ]}
                    onChange={(event) => {
                      setFieldValue("status_pagamento", event.target.value);
                    }}
                    required={true}
                  />
                </Grid>
              </Grid>
              <Grid container width="100%" my={2} spacing={1}>
                <Grid item xs={values?.recorrencia !== "nenhuma" ? 4 : 6}>
                  <SelectAmbiental
                    fullWidth={true}
                    label={"Colaborador responsável:"}
                    value={values.usuario}
                    name="usuario"
                    onChange={(event) => {
                      const idUsuario = Number(event.target.value);
                      setFieldValue("usuario", idUsuario);
                    }}
                    options={usuarios}
                    nameOptionDescription={"name"}
                    nameOptionValue={"id"}
                    required={true}
                  />
                  <FormHelperText>
                    Este colaborador receberá notificações / atualizações sobre
                    o estado da agenda.
                  </FormHelperText>
                </Grid>
                {values.recorrencia !== "nenhuma" ? (
                  <>
                    <Grid item xs={4}>
                      <InputAmbiental
                        fullWidth={true}
                        label={"Data início recorrência:"}
                        type="date"
                        name="inicio_recorrencia"
                        value={
                          values.inicio_recorrencia
                            ? moment
                                .utc(values?.inicio_recorrencia)
                                .format("YYYY-MM-DD")
                            : null
                        }
                        onChange={(event) => {
                          setFieldValue(
                            "inicio_recorrencia",
                            event.target.value
                          );
                        }}
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <InputAmbiental
                        fullWidth={true}
                        label={"Data final recorrência:"}
                        type="date"
                        name="fim_recorrencia"
                        value={
                          values.fim_recorrencia
                            ? moment
                                .utc(values?.fim_recorrencia)
                                .format("YYYY-MM-DD")
                            : null
                        }
                        onChange={(event) => {
                          setFieldValue("fim_recorrencia", event.target.value);
                        }}
                        required={true}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
              {values.recorrencia === "semanal" ? (
                <Grid container width="100%" my={2}>
                  <Grid item xs={12}>
                    <FormGroup>
                      <InputLabel
                        my={1}
                        style={{
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        Dias da Semana*:
                      </InputLabel>
                      <Select
                        multiple
                        value={values.dias ?? []}
                        name={"dias"}
                        onChange={(event) => {
                          handleDayChange(event.target.value);
                        }}
                        style={{
                          borderRadius: "10px",
                          border: "1px solid #4E5526",
                          backgroundColor: "#FFFFFF",
                          height: "40px",
                          alignItems: "center",
                          boxSizing: "border-box",
                          outline: "none",
                        }}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip-checkbox"
                            label="Selecionar Dias"
                          />
                        }
                        renderValue={() => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {values.dias.length === 1 ? (
                              <Chip label={DIAS_SEMANA[values.dias[0]?.dia]} />
                            ) : (
                              <Chip
                                label={`${values.dias.length} dias selecionados`}
                              />
                            )}
                          </Box>
                        )}
                      >
                        {Object.entries(DIAS_SEMANA).map(
                          ([chave, valor], index) => (
                            <MenuItem key={index} value={chave}>
                              <Checkbox
                                checked={values?.dias?.some(
                                  (dia) => String(dia?.dia) === String(chave)
                                )}
                              />
                              <ListItemText primary={valor} />
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormGroup>
                  </Grid>
                </Grid>
              ) : null}
            </DialogContent>
            <DialogActions
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingRight: 40,
              }}
            >
              <ActionButton
                title={id ? "Atualizar" : "Criar"}
                to={null}
                color="blue"
                icon={<BorderColorIcon sx={{ fontSize: "16px" }} />}
                isSubmitAction={true}
                action={() => {
                  handleSubmit(values, resetForm);
                }}
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ModalNovaConta;
