import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MetricCard from "../MetricCard/MetricCard";
import PaginationComponent from "../Pagination/Pagination";
import { DateRangePicker } from "react-dates";
import ActionButton from "../ActionButton/ActionButton";
import BotoesExportarDados from "../BotoesExportarDados";
import AbaDashboard from "app/components/AbaDashboard";

import { ChevronLeft, Delete } from "@mui/icons-material";
import { ChevronRight } from "@mui/icons-material";
import { CalendarMonth } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import QueueIcon from "@mui/icons-material/Queue";
import VisibilityIcon from "@mui/icons-material/Visibility";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import ModalNovoLancamento from "../ModalNovoLancamento";

const ControleCaixaArea = ({
  indicators,
  page,
  perPage,
  setPage,
  setPerPage,
  count,
  inputValue,
  setInputValue,
  handleSearchLancamentos,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  handleDatesChange,
  focusedInput,
  handleFocusChange,
  controleCaixa,
  modalAlert,
  setFiltrosPesquisa,
  getControleCaixa,
  updateState,
  updateFluxoIndicators,
}) => {
  const initialValues = {
    observacao: "",
  };
  const [isModalNovoLancamentoAberto, setIsModalNovoLancamentoAberto] =
    useState(false);

  const toggleModal = () => {
    setIsModalNovoLancamentoAberto(!isModalNovoLancamentoAberto);
  };

  const navigate = useNavigate();
  return (
    <>
      <Grid container width="100%" my={2} spacing={2}>
        <Grid item xs={4}>
          <MetricCard title={"Caixa"} value={indicators.caixa} />
        </Grid>
        <Grid item xs={4}>
          <MetricCard title={"BB-51387-3"} value={indicators.bb_51387_3} />
        </Grid>
        <Grid item xs={4}>
          <MetricCard
            title={"Total"}
            value={indicators.total}
            bgColor={"#4E5526"}
          />
        </Grid>
      </Grid>
      <Card sx={{ width: "100%", minHeight: "100px", my: 3, p: 2 }}>
        <Grid container width="100%" spacing={2}>
          <Grid item xs={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <TextField
                variant="standard"
                placeholder="Pesquisar..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearchLancamentos(inputValue);
                  }
                }}
                InputProps={{
                  sx: {
                    borderRadius: 2,
                    border: "1px solid #4E5526",
                    paddingLeft: 2,
                    height: "36px",
                    "&:hover": { borderColor: "#3B4220" },
                    "&.Mui-focused": { borderColor: "#4E5526" },
                    color: "#4E5526",
                    "&::placeholder": { color: "red", opacity: 1 },
                    width: 350,
                  },
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        sx={{
                          backgroundColor: "#4E5526",
                          borderRadius: 2,
                          minWidth: "36px",
                          height: "36px",
                          padding: 0,
                          "&:hover": { backgroundColor: "#3B4220" },
                        }}
                        onClick={() => handleSearchLancamentos(inputValue)}
                      >
                        <SearchIcon sx={{ color: "#fff", fontSize: 20 }} />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Stack
              width="100%"
              direction={"row"}
              gap={1}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <DateRangePicker
                small={true}
                startDate={startDate}
                startDatePlaceholderText="Data inicial"
                startDateId="data_inicio"
                endDate={endDate}
                endDateId="data_final"
                endDatePlaceholderText="Data final"
                onDatesChange={handleDatesChange}
                focusedInput={focusedInput}
                onFocusChange={handleFocusChange}
                isOutsideRange={() => false}
              />
              {startDate && endDate ? (
                <ActionButton
                  color="red"
                  title={"Resetar"}
                  action={() => {
                    setFiltrosPesquisa({
                      data_inicio: null,
                      data_final: null,
                    });
                    setStartDate(null);
                    setEndDate(null);
                  }}
                  icon={<CalendarMonth />}
                />
              ) : null}
            </Stack>
          </Grid>
          <Grid item xs={1} display={"flex"} justifyContent={"flex-end"}>
            <BotoesExportarDados
              dados_planilha={controleCaixa}
              nome_arquivo_planilha={"controle_caixa_"}
              titulo={"Lançamentos - Controle de Caixa"}
              colunas_planilha={[
                "id",
                "observacao",
                "entrada_caixa",
                "entrada_51387_3",
                "saida_caixa",
                "saida_51387_3",
              ]}
            />
          </Grid>
          <Grid item xs={3} display={"flex"} justifyContent={"flex-end"}>
            <ActionButton
              title="Novo Lançamento"
              to={null}
              color="green"
              icon={<QueueIcon />}
              action={() => {
                toggleModal();
              }}
              fullWidth={true}
            />
          </Grid>
        </Grid>
        <Grid
          container
          width="100%"
          alignItems={"center"}
          my={2}
          p={1}
          spacing={2}
        >
          <Grid item xs={1}>
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              Data
            </Typography>
          </Grid>
          <Grid item xs={2.5}>
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              Descrição
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              Entrada Caixa
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              Entrada BB 51387-3
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              Saída Caixa
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              Saída BB 51387-3
            </Typography>
          </Grid>
          <Grid
            item
            xs={0.5}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              fontWeight={600}
              fontSize={18}
              style={{ color: "#212529" }}
            >
              Ações
            </Typography>
          </Grid>
        </Grid>
        {controleCaixa && controleCaixa.length > 0 ? (
          controleCaixa.map((lancamento, index) => (
            <Grid
              container
              width="100%"
              alignItems={"center"}
              my={-2}
              p={2}
              spacing={2}
              key={`${lancamento.id}-${index}`}
              style={{
                borderBottom: "1px solid #4E5526",
                paddingBottom: 14,
              }}
            >
              <Grid item xs={1}>
                {lancamento?.data
                  ? moment.utc(lancamento.data).format("DD/MM/YYYY")
                  : "N/I"}
              </Grid>
              <Grid item xs={2.5}>
                {lancamento?.observacao ? lancamento?.observacao : "N/I"}
              </Grid>
              <Grid item xs={2}>
                {Number(lancamento?.entrada_caixa).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </Grid>
              <Grid item xs={2}>
                {Number(lancamento?.entrada_51387_3).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </Grid>
              <Grid item xs={2}>
                {Number(lancamento?.saida_caixa).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </Grid>
              <Grid item xs={2}>
                {Number(lancamento?.saida_51387_3).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </Grid>
              <Grid
                item
                xs={0.5}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                <VisibilityIcon
                  sx={{ cursor: "pointer", fontSize: 24 }}
                  onClick={() => {
                    navigate(`/app/editar-lancamento/${lancamento.id}`);
                  }}
                />
                <DeleteIcon
                  sx={{ color: "#C84E4D", cursor: "pointer", fontSize: 24 }}
                  onClick={() => {
                    modalAlert(lancamento.id);
                  }}
                />
              </Grid>
            </Grid>
          ))
        ) : (
          <Box
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            p={2}
          >
            Nenhum lançamento registrado
          </Box>
        )}
        <PaginationComponent
          page={page}
          setPage={setPage}
          count={count}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Card>
      <ModalNovoLancamento
        aberto={isModalNovoLancamentoAberto}
        handleClose={toggleModal}
        getLancamentos={getControleCaixa}
        updateFluxoIndicators={updateFluxoIndicators}
        updateState={getControleCaixa}
      />
    </>
  );
};

export default ControleCaixaArea;
