import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  Paper,
  Stack,
  useMediaQuery,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  TextField,
} from "@mui/material";
import BotaoVoltarBreadCrumbs from "app/components/BotaoVoltarBreadCrumbs";
import { PermissionContext } from "app/contexts/PermissionContext";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { Form, Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import makeAnimated from "react-select/animated";
import * as yup from "yup";
import DescriptionIcon from "@mui/icons-material/Description";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import InputAmbiental from "@jumbo/components/InputAmbiental";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";
import ActionButton from "app/components/ActionButton/ActionButton";
import BorderColorIcon from "@mui/icons-material/BorderColor";

const validationSchema = yup.object().shape({
  descricao: yup
    .string("Insira a descrição do Plano de conta")
    .required("Preenchimento obrigatório"),
  codigo: yup
    .number("Insira o código do Plano de conta")
    .required("Preenchimento obrigatório"),
});

const PlanoContasForm = () => {
  const initialValues = {
    descricao: "",
    codigo: "",
    // categoria_id: null,
  };
  const { id } = useParams();
  const [planoConta, setPlanoConta] = useState(initialValues);
  const [categories, setCategories] = useState([]);

  const [primaryCategory, setPrimaryCategory] = useState(null);
  const [secondaryCategory, setSecondaryCategory] = useState(null);
  const [tertiaryCategory, setTertiaryCategory] = useState(null);

  const [isSelected, setIsSelected] = useState(false);

  // const [quaternaryCategory, setQuaternaryCategory] = useState(null);

  const Swal = useSwalWrapper();
  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  if (!hasPermission("Financeiro", "read")) {
    navigate("/app");
  }
  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getPlanoConta = useCallback(async () => {
    try {
      ApiService.get(`/planocontas/${id}`)
        .then((response) => {
          setPlanoConta({
            ...response.data,
          });
          setIsSelected(response.data.in_regime_competencia);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleCheckboxChange = (checked) => {
    setIsSelected(!checked);
  };

  const getCategories = useCallback(async () => {
    try {
      ApiService.get(`/planocontas/categories`)
        .then((response) => {
          console.log("response.data", response.data);
          setCategories(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const clearCategorySelect = useCallback(async (currentStep) => {
    if (currentStep == 1) {
      setSecondaryCategory(null);
      setTertiaryCategory(null);
    } else if (currentStep == 2) {
      setTertiaryCategory(null);
    }
  }, []);

  useEffect(() => {
    if (id) {
      getPlanoConta();
    }

    getCategories();
  }, []);

  useEffect(() => {
    // console.log('useEffect', categories.length)
    if (planoConta.plano_conta_id && categories.length > 0) {
      const planoContaCategory = categories.find(
        (opt) => opt.id == planoConta.plano_conta_id
      );

      console.log(planoContaCategory);

      if (planoContaCategory.lista_plano_mae_id) {
        const categoriesList = planoContaCategory.lista_plano_mae_id.split(",");
        const length = categoriesList.length;

        console.log(categoriesList);

        const thirdCategory =
          length == 3 ? categoriesList[0] : planoConta.plano_conta_id;
        const fourthCategory = length == 3 ? planoConta.plano_conta_id : null;

        console.log("categoriesList", categoriesList);

        setPrimaryCategory(categoriesList[length - 1]);
        setSecondaryCategory(categoriesList[length - 2]);
        setTertiaryCategory(thirdCategory);
      }
    }
  }, [planoConta, categories]);

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    // Define qual é o último nível de subcategoria, apenas ela é adicionada para ser enviada pelo form
    const plano_conta_id = isLastCategoryLevel
      ? tertiaryCategory
      : secondaryCategory;
    const in_regime_competencia = isSelected ? true : false;

    const allValuesEdit = { ...values, plano_conta_id, in_regime_competencia };
    delete allValuesEdit.id;
    delete allValuesEdit.in_demonstrativo_resultados;
    delete allValuesEdit.in_demonstrativo_setores_produtivos;
    delete allValuesEdit.createdAt;
    delete allValuesEdit.updatedAt;
    delete allValuesEdit.deletedAt;

    if (id) {
      ApiService.put(`/planocontas/${id}`, allValuesEdit)
        .then((response) => {
          if (response.data?.response?.status === 400) {
            toast("error", response.data?.response.error);
            return;
          }
          if (response.status === 200) {
            toast("success", "Atualizado com sucesso!");
            navigate("/app/plano-de-contas");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response.data) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } else {
      await ApiService.post("/planocontas", allValuesEdit)
        .then((response) => {
          console.log(response);
          if (response.data?.response?.status === 400) {
            toast("error", response.data?.response.error);
            return;
          }
          if (response.status === 201) {
            toast("success", "Criado com sucesso");
            resetForm();
            navigate("/app/plano-de-contas");
          }
        })
        .catch((error) => {
          let message = error.response.data.message[0];
          toast("error", message);

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    }
    setSubmitting(false);
  };
  const zz = categories.filter(
    (opt) => opt.codigo.length < 3 && opt.plano_mae_id == secondaryCategory
  );
  const isLastCategoryLevel = zz ? zz.length > 0 : false;

  // console.log('aaa', categories.filter(opt => opt.codigo.length < 3 && opt.plano_mae_id == 711).length > 0)

  return (
    <Box sx={{ position: "relative" }}>
      <JumboContentLayout layoutOptions={layoutOptions}>
        {lg && (
          <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
        )}
        <BotaoVoltarBreadCrumbs
          handleBack={() => {
            navigate(`/app/plano-de-contas`);
          }}
        />
        <PageHeader
          title={
            id && planoConta
              ? `Plano de Conta > Editar`
              : "Plano de Conta > Cadastro"
          }
          icon={
            <DescriptionIcon
              sx={{ color: "#4E5526", marginRight: 1, fontSize: 40 }}
            />
          }
        />
        <Formik
          initialValues={planoConta}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            errors,
            touched,
            setTouched,
            setSubmitting,
            resetForm
          }) => (
            <Form
              style={{
                width: "100%",
                minHeight: "55vh",
                display: "flex",
                flexDirection: "column",
              }}
              noValidate
              autoComplete="off"
            >
              <Grid
                container
                width={"100%"}
                alignContent={"center"}
                spacing={2}
              >
                <Grid item xs={6}>
                  {" "}
                  <InputAmbiental
                    label={"Código"}
                    fullWidth={true}
                    required={"true"}
                    name={"codigo"}
                    type="number"
                    value={values.codigo}
                    onChange={(event, value) => {
                      setFieldValue("codigo", Number(event.target.value));
                    }}
                    handleBlur={(e) => {
                      handleBlur(e);
                      setTouched({ ...touched, codigo: true });
                    }}
                  />
                  {touched.codigo && errors.codigo && (
                    <div style={{ color: "red" }}>{errors.codigo}</div>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {" "}
                  <InputAmbiental
                    label={"Descrição"}
                    fullWidth={true}
                    required={"true"}
                    name={"descricao"}
                    type="text"
                    value={values.descricao}
                    onChange={(event, value) => {
                      setFieldValue("descricao", event.target.value);
                    }}
                    handleBlur={(e) => {
                      handleBlur(e);
                      setTouched({ ...touched, descricao: true });
                    }}
                  />
                  {touched.descricao && errors.descricao && (
                    <div style={{ color: "red" }}>{errors.descricao}</div>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                width={"100%"}
                alignContent={"center"}
                my={2}
                spacing={2}
              >
                <Grid item xs={!primaryCategory ? 6 : 3}>
                  <SelectAmbiental
                    label={"Categoria"}
                    name="categoria"
                    value={primaryCategory ? primaryCategory : ""}
                    onChange={(event) => {
                      setPrimaryCategory(Number(event.target.value));
                      clearCategorySelect(1);
                    }}
                    fullWidth={true}
                    options={categories.filter(
                      (opt) => opt.plano_mae_id == null
                    )}
                    nameOptionValue={"id"}
                    nameOptionDescription={"descricao"}
                  />
                </Grid>
                {primaryCategory ? (
                  <Grid item xs={3}>
                    <SelectAmbiental
                      label={"Subcategoria 1"}
                      name="subcategoria"
                      value={secondaryCategory ? secondaryCategory : ""}
                      onChange={(event) => {
                        setSecondaryCategory(Number(event.target.value));
                        clearCategorySelect(2);
                      }}
                      fullWidth={true}
                      options={categories.filter(
                        (opt) => opt.plano_mae_id == primaryCategory
                      )}
                      nameOptionValue={"id"}
                      nameOptionDescription={"descricao"}
                    />
                  </Grid>
                ) : null}
                {secondaryCategory && isLastCategoryLevel && (
                  <Grid item xs={3}>
                    <SelectAmbiental
                      label={"Subcategoria 2"}
                      value={tertiaryCategory ? tertiaryCategory : ""}
                      onChange={(event) => {
                        setTertiaryCategory(Number(event.target.value));
                        clearCategorySelect(3);
                      }}
                      options={categories.filter(
                        (opt) => opt.plano_mae_id == secondaryCategory
                      )}
                      fullWidth={true}
                      nameOptionValue={"id"}
                      nameOptionDescription={"descricao"}
                    />
                  </Grid>
                )}
                <Grid item xs={!primaryCategory ? 6 : 3}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "16px",
                      marginTop: "32px",
                    }}
                  >
                    <Checkbox
                      checked={isSelected}
                      onChange={() => handleCheckboxChange(isSelected)}
                    />
                    <InputLabel
                      id="role-usuario"
                      style={{
                        fontWeight: 600,
                        color: "black",
                        marginLeft: "8px",
                      }}
                    >
                      Pertence ao regime de caixa
                    </InputLabel>
                  </div>
                </Grid>
              </Grid>
              <Box
                width={"100%"}
                display={"flex"}
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
                marginTop={18}
                sx={{
                  position: "sticky",
                  right: 0,
                  bottom: 0,
                }}
              >
                <ActionButton
                  title={id ? "Atualizar" : "Criar"}
                  to={null}
                  color="blue"
                  icon={<BorderColorIcon />}
                  isSubmitAction={true}
                  action={() => {
                    handleSubmit(
                      values,
                      {setSubmitting,
                      resetForm,
                      setFieldValue}
                    );
                  }}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </JumboContentLayout>
    </Box>
  );
};

export default PlanoContasForm;
