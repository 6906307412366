import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function MetricCard({
  title,
  value,
  bgColor = null,
  secondaryValue = null,
}) {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  function formatUndefined(value) {
    if (!value) {
      return 0;
    }
    return value;
  }

  const percentagePrimaryTitles = [
    "Margem líquida",
    "MC%",
    "Representatividade custo fixo",
  ];

  const percentageTitles = ["Saldo final"];

  value = formatUndefined(value);
  secondaryValue = formatUndefined(secondaryValue);

  const formattedValuePrimary = !percentagePrimaryTitles.includes(title)
    ? formatter.format(value)
    : (value * 100)?.toFixed(2) + "%";
  const formattedValue = percentageTitles.includes(title)
    ? formatter.format(secondaryValue)
    : (secondaryValue * 100)?.toFixed(2) + "%";

  const theme = value >= 0 ? (bgColor ? bgColor : "#98A949") : "#C7574C";
  const themeSecondary = secondaryValue > 0 ? "success.main" : "error.light";

  return (
    <Box>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: theme,
          height: "120px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <CardContent>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
            <Typography
              variant="h3"
              fontWeight={"bold"}
              sx={{ marginRight: "5px", color: "#FFF" }}
            >
              {formattedValuePrimary}
            </Typography>
            <Typography variant="h4" fontWeight={"bold"} sx={{ color: "#FFF", textWrap: "wrap" }}>
              {secondaryValue ? "| " + formattedValue : ""}
            </Typography>
          </Box>

          <Typography variant="h5" color="success.contrastText">
            {title}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
