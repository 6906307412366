import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  Paper,
  Stack,
  useMediaQuery,
  InputLabel,
  TextField,
  Typography,
  Select,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import pt from "dayjs/locale/pt-br";
import { ptBR } from "@mui/x-date-pickers/locales";
import { PermissionContext } from "app/contexts/PermissionContext";
import TreeSelect from "app/components/TreeSelect/TreeSelect";
import MoneyInput from "app/components/MoneyInput/MoneyInput";
import formatMoney from "app/utils/formatMoney";
import BotaoVoltarBreadCrumbs from "app/components/BotaoVoltarBreadCrumbs";
import DescriptionIcon from "@mui/icons-material/Description";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import SelectAmbiental from "@jumbo/components/SelectAmbiental";
import InputAmbiental from "@jumbo/components/InputAmbiental";
import ActionButton from "app/components/ActionButton/ActionButton";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import moment from "moment";

const validationSchema = yup.object().shape({
  // data: yup
  //   .string("Insira a Data do lançamento")
  //   .required("Preenchimento obrigatório"),
  // plano_conta_id: yup
  //   .string("Insira a Categoria")
  //   .required("Preenchimento obrigatório"),
});

const ControleCaixaForm = () => {
  const initialValues = {
    observacao: "",
  };
  const { id } = useParams();
  const [lancamento, setLancamento] = useState(initialValues);
  const [categories, setCategories] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [nodes, setNodes] = useState(null);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);

  const [primaryCategory, setPrimaryCategory] = useState(null);
  const [secondaryCategory, setSecondaryCategory] = useState(null);
  const [tertiaryCategory, setTertiaryCategory] = useState(null);
  // const [quaternaryCategory, setQuaternaryCategory] = useState(null);

  const Swal = useSwalWrapper();
  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  if (!hasPermission("Financeiro", "read")) {
    navigate("/app");
  }

  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getLancamento = useCallback(async () => {
    try {
      ApiService.get(`/controlecaixa/${id}`)
        .then((response) => {
          setLancamento({
            ...response.data,
            data: moment.utc(response.data?.data).format("YYYY-MM-DD")
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getCategories = useCallback(async () => {
    try {
      ApiService.get(`/planocontas/categories`)
        .then((response) => {
          console.log(response.data);
          setCategories(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const clearCategorySelect = useCallback(async (currentStep) => {
    if (currentStep == 1) {
      setSecondaryCategory(null);
      setTertiaryCategory(null);
    } else if (currentStep == 2) {
      setTertiaryCategory(null);
    }
  }, []);

  useEffect(() => {
    if (id) {
      getLancamento();
    }

    getCategories();
  }, []);

  useEffect(() => {
    if (lancamento.plano_conta_id && categories.length > 0) {
      const lancamentoCategory = categories.find(
        (opt) => opt.id == lancamento.plano_conta_id
      );
      const categoriesList = lancamentoCategory.lista_plano_mae_id.split(",");
      const length = categoriesList.length;

      const thirdCategory =
        length == 3 ? categoriesList[0] : lancamento.plano_conta_id;
      const fourthCategory = length == 3 ? lancamento.plano_conta_id : null;

      console.log("categoriesList", categoriesList);

      setPrimaryCategory(categoriesList[length - 1]);
      setSecondaryCategory(categoriesList[length - 2]);
      setTertiaryCategory(thirdCategory);
    }
  }, [lancamento, categories]);

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    // Define qual é o último nível de subcategoria, apenas ela é adicionada para ser enviada pelo form
    // const plano_conta_id = isLastCategoryLevel ? tertiaryCategory : secondaryCategory;

    let allValuesEdit = {
      ...values,
      entrada_caixa:
        typeof values.entrada_caixa == "string"
          ? formatMoney(values.entrada_caixa)
          : values.entrada_caixa,
      entrada_51387_3:
        typeof values.entrada_51387_3 == "string"
          ? formatMoney(values.entrada_51387_3)
          : values.entrada_51387_3,
      entrada_51403_9:
        typeof values.entrada_51403_9 == "string"
          ? formatMoney(values.entrada_51403_9)
          : values.entrada_51403_9,
      saida_caixa:
        typeof values.saida_caixa == "string"
          ? formatMoney(values.saida_caixa)
          : values.saida_caixa,
      saida_51387_3:
        typeof values.saida_51387_3 == "string"
          ? formatMoney(values.saida_51387_3)
          : values.saida_51387_3,
      saida_51403_9:
        typeof values.saida_51403_9 == "string"
          ? formatMoney(values.saida_51403_9)
          : values.saida_51403_9,
    };
    delete allValuesEdit.id;
    delete allValuesEdit.createdAt;
    delete allValuesEdit.updatedAt;
    delete allValuesEdit.deletedAt;

    console.log("values", values);
    console.log("allValuesEdit", allValuesEdit);
    console.log("id", id);

    if (id > 0) {
      console.log("update");

      ApiService.put(`/controlecaixa/${id}`, allValuesEdit)
        .then((response) => {
          toast("success", "Atualizado com sucesso!");
          if (response.status === 200) {
            navigate("/app/controle-de-caixa");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response.data) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } else {
      console.log("create");

      await ApiService.post("/controlecaixa", allValuesEdit)
        .then((response) => {
          toast("success", "Criado com sucesso");
          resetForm();
          if (response.status === 201) {
            navigate("/app/controle-de-caixa");
          }
        })
        .catch((error) => {
          let message = error.response.data.message[0];
          toast("error", message);

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    }
    setSubmitting(false);
  };

  return (
    <Box style={{ position: "relative" }}>
      <JumboContentLayout layoutOptions={layoutOptions}>
        {lg && (
          <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
        )}
        <BotaoVoltarBreadCrumbs
          handleBack={() => {
            navigate(`/app/controle-de-caixa`);
          }}
        />
        <PageHeader
          title={"Lançamentos > Editar"}
          icon={
            <DescriptionIcon
              sx={{ color: "#4E5526", marginRight: 1, fontSize: 40 }}
            />
          }
        />

        <Formik
          initialValues={lancamento}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            setFieldValue,
            errors,
            setSubmitting,
            resetForm,
          }) => (
            <Form style={{ width: "100%" }} noValidate autoComplete="off">
              <Grid container width="100%" spacing={2}>
                <Grid item xs={9}>
                  <InputLabel
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      marginBottom: 12,
                    }}
                    htmlFor="grouped-native-select"
                  >
                    Categoria:
                  </InputLabel>
                  <TreeSelect
                    categories={categories}
                    setFieldValue={setFieldValue}
                    defaultValue={values.plano_conta_id}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputAmbiental
                    type="date"
                    label={"Data de Lançamento"}
                    value={values.data}
                    onChange={(event) => {
                      setFieldValue("data", event.target.value);
                    }}
                    name={"data"}
                  />
                </Grid>
              </Grid>
              <Grid container width="100%" spacing={2} my={1}>
                <Grid item xs={12}>
                  <InputAmbiental
                    name={"observacao"}
                    value={values.observacao}
                    onChange={(event) => {
                      setFieldValue("observacao", event.target.value);
                    }}
                    fullWidth={true}
                    label={"Observação"}
                  />
                </Grid>
              </Grid>
              <Grid container width="100%" spacing={2} my={1}>
                <Grid item xs={3}>
                  <InputAmbiental
                    type="text"
                    label={"Entrada Caixa"}
                    labelColor="#4E5526"
                    name={"entrada_caixa"}
                    hasMask={true}
                    maskType={"dinheiro"}
                    onChange={(event, value) => {
                      setFieldValue("entrada_caixa", value);
                    }}
                    value={values.entrada_caixa}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputAmbiental
                    type="text"
                    label={"Entrada 51387_3"}
                    labelColor="#4E5526"
                    name={"entrada_51387_3"}
                    hasMask={true}
                    maskType={"dinheiro"}
                    onChange={(event, value) => {
                      setFieldValue("entrada_51387_3", value);
                    }}
                    value={values.entrada_51387_3}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputAmbiental
                    type="text"
                    label={"Saída Caixa"}
                    labelColor="#CA0606"
                    name={"saida_caixa"}
                    hasMask={true}
                    maskType={"dinheiro"}
                    onChange={(event, value) => {
                      setFieldValue("saida_caixa", value);
                    }}
                    value={values.saida_caixa}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputAmbiental
                    type="text"
                    label={"Saída Caixa 51387_3"}
                    labelColor="#CA0606"
                    name={"saida_51387_3"}
                    hasMask={true}
                    maskType={"dinheiro"}
                    onChange={(event, value) => {
                      setFieldValue("saida_51387_3", value);
                    }}
                    value={values.saida_51387_3}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
              <Box
                width={"100%"}
                display={"flex"}
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
                sx={{
                  position: "sticky",
                  right: 0,
                  bottom: 0,
                  marginTop: 12
                }}
              >
                <ActionButton
                  title={"Atualizar"}
                  to={null}
                  color="blue"
                  icon={<BorderColorIcon />}
                  isSubmitAction={true}
                  action={() => {
                    handleSubmit(values, {
                      setSubmitting,
                      resetForm,
                      setFieldValue,
                    });
                  }}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </JumboContentLayout>
    </Box>
  );
};

export default ControleCaixaForm;
