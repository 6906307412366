import React, { useState } from "react";
import { TreeSelect as TS } from "antd";

function TreeSelect({ categories, setFieldValue, defaultValue }) {
  const [value, setValue] = useState();
  // Construção do array de categorias no formato que o select aceita

  // Categoria nível 1
  const treeData = categories
    .filter((item) => {
      return item.plano_mae_id == null;
    })
    .map((item) => {
      return {
        value: item.id.toString(),
        title: `${item.codigo} - ${item.descricao}`,
        codigo: item.codigo,
        selectable: false,
      };
    });

  // SubCategoria nível 1
  treeData.map((item) => {
    item.children = categories
      .filter((subItem) => {
        const lenght = subItem.lista_plano_mae_id
          ? subItem.lista_plano_mae_id.split(",").length
          : 0;

        return lenght == 1 && subItem.plano_mae_id == item.value;
      })
      .map((subItem) => {
        return {
          value: subItem.id.toString(),
          title: `${subItem.codigo} - ${subItem.descricao}`,
          codigo: subItem.codigo,
          selectable: false,
        };
      });
  });

  // SubCategoria nível 2
  treeData.map((item) => {
    item.children.map((subItem) => {
      subItem.children = categories
        .filter((subSubItem) => {
          const lenght = subSubItem.lista_plano_mae_id
            ? subSubItem.lista_plano_mae_id.split(",").length
            : 0;

          return lenght == 2 && subSubItem.plano_mae_id == subItem.value;
        })
        .map((subSubItem) => {
          const isSelectable =
            (subSubItem.codigo.length == 3 &&
              !["480", "490"].includes(subSubItem.codigo)) ||
            (subSubItem.codigo.length == 2 &&
              ["71", "72", "73"].includes(subSubItem.codigo));

          return {
            value: subSubItem.id.toString(),
            title: `${subSubItem.codigo} - ${subSubItem.descricao}`,
            codigo: subSubItem.codigo,
            selectable: isSelectable,
          };
        });
    });
  });

  // SubCategoria nível 3
  treeData.map((item) => {
    item.children.map((subItem) => {
      subItem.children.map((subSubItem) => {
        subSubItem.children = categories
          .filter((subSubSubItem) => {
            const lenght = subSubSubItem.lista_plano_mae_id
              ? subSubSubItem.lista_plano_mae_id.split(",").length
              : 0;

            return (
              lenght == 3 && subSubSubItem.plano_mae_id == subSubItem.value
            );
          })
          .map((subSubSubItem) => {
            return {
              value: subSubSubItem.id.toString(),
              title: `${subSubSubItem.codigo} - ${subSubSubItem.descricao}`,
              codigo: subSubSubItem.codigo,
            };
          });
      });
    });
  });

  const handleFilter = (inputValue, treeNode) => {
    const { title, codigo } = treeNode;

    return (
      title.toLowerCase().includes(inputValue.toLowerCase()) ||
      codigo.includes(inputValue)
    );
  };

  return (
    <TS
      showSearch
      // allowClear
      id="plano_conta_id"
      name="plano_conta_id"
      treeDefaultExpandAll
      style={{
        width: "100%",
        borderRadius: "10px",
        border: "1px solid #4E5526",
        backgroundColor: "#FFFFFF",
        minHeight: "40px",
        alignItems: "center",
        boxSizing: "border-box",
        color: "black",
        outline: "none",
      }}
      value={defaultValue}
      dropdownStyle={{
        maxHeight: 400,
        overflow: "auto",
        zIndex: 9999, 
      }}
      placeholder="Selecione uma categoria"
      onChange={(value) => {
        setValue(Number(value));
        setFieldValue("plano_conta_id", Number(value));
      }}
      treeData={treeData}
      filterTreeNode={handleFilter}
    />
  );
}

export default TreeSelect;
